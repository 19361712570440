import React, { useEffect, useState } from 'react';
import { Organization } from 'types/organization';
import { validateYup, Validation } from 'utils/validation';
import { Dialog } from 'foundation/dialog';
import * as yup from 'yup';
import { Button, Form, FormInput } from 'components/foundation';
import OrganizationResources from 'remote/resources/organizations';
import { useDefaultErrorHandler } from 'remote/error-handler';
import { useNotify } from 'state/notifications';

export interface LinkTenantModalProps {
  organization: Organization;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface Data {
  tenantId: string;
}

const schema = yup.object({
  tenantId: yup.string().required(),
});

const defaults = (): Data => {
  return {
    tenantId: undefined,
  };
};

const validate = (data: Data) => {
  return validateYup(schema, data);
};

export const LinkTenantModal = ({
  organization,
  open,
  onClose,
  onSuccess,
}: LinkTenantModalProps) => {
  const notify = useNotify();
  const [data, setData] = useState(defaults());
  const [validation, setValidation] = useState<Validation<Data>>({});
  const [loading, setLoading] = useState(false);
  const defaultErrorHandler = useDefaultErrorHandler();

  const resetForm = () => {
    setData(defaults());
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleTenantIdChange = e => {
    const value = e.target.value;
    setData(prev => ({ ...prev, tenantId: value }));
  };

  const handleSubmit = async () => {
    const errors = validate(data);
    if (errors) {
      setValidation(errors);
      return;
    }

    setLoading(true);

    try {
      await OrganizationResources.linkTenant(organization.id, data.tenantId);
      onSuccess();
    } catch (e) {
      if (e.response.status === 409) {
        notify.error(e.responseMessage);
      } else {
        defaultErrorHandler(e);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Dialog.Header>Link Tenant to Organization</Dialog.Header>
      <Dialog.Content>
        <Form onSubmit={handleSubmit}>
          <FormInput
            label="Tenant UUID"
            placeholder="Enter Tenant UUID..."
            value={data.tenantId || ''}
            onChange={handleTenantIdChange}
            autoFocus
            fluid
            errorText={validation.tenantId?.message}
            data-testid="tenant-id-input"
          />
        </Form>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={handleClose} fill="text">
          Cancel
        </Button>
        <Button onClick={handleSubmit} loading={loading} data-testid="link-tenant-submit">
          Link
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
