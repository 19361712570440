import { KeyPairItem, KeyPairSection } from 'components/application/key-pair';
import { Alert, Button } from 'components/foundation';
import React, { useState } from 'react';
import { InternalSsoConfig } from 'types/sso-config';
import DeleteSsoConfigModal from './delete-sso-config-modal';
import EditSsoConfigModal from './edit-sso-config-modal';
import { auth0Domain } from 'utils/auth0';

export interface SsoConfigTableProps {
  config: InternalSsoConfig;
  orgAuth0Id: string;
  onRefreshSsoConfig: () => void;
}

const SsoConfigTable = ({ config, orgAuth0Id, onRefreshSsoConfig }: SsoConfigTableProps) => {
  const loginWithOrgLink = `${location.origin}?org_id=${orgAuth0Id}&autosubmit=true#organization-lookup`;

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [configDeleted, setConfigDeleted] = useState(false);

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const editSaveAndClose = () => {
    closeEditModal();
    onRefreshSsoConfig();
  };

  const deleteSaveAndClose = () => {
    closeDeleteModal();
    setConfigDeleted(true);
  };

  const handleClickEdit = () => {
    setEditModalOpen(true);
  };

  const handleClickDelete = () => {
    setDeleteModalOpen(true);
  };

  return (
    <>
      {configDeleted ? (
        <Alert
          className="tw-mt-2"
          type="danger"
          data-testid="sso-config-deleted-message"
          description="This config was deleted."
        />
      ) : (
        <div className="tw-mt-5" data-testid="sso-config-table">
          <div className="tw-flex tw-justify-between tw-flex-nowrap tw-mb-2">
            <h3>{config.displayName}</h3>
            <div>
              <Button
                className="tw-mr-3"
                color="danger"
                fill="outlined"
                data-testid="delete-sso-config-button"
                onClick={handleClickDelete}
              >
                Delete
              </Button>
              <Button
                color="primary"
                data-testid="edit-sso-config-button"
                onClick={handleClickEdit}
              >
                Edit
              </Button>
            </div>
          </div>

          <KeyPairSection>
            <KeyPairItem label="ID">{config.ssoConfigId}</KeyPairItem>
            <KeyPairItem label="Display Name">{config.displayName}</KeyPairItem>
            <KeyPairItem label="IDP Type">{config.idpType}</KeyPairItem>
            <KeyPairItem label="Channel">{config.channel}</KeyPairItem>
            <KeyPairItem label="Client ID">{config.clientID}</KeyPairItem>
            <KeyPairItem label="Discovery URI" href={config.discoveryURI}>
              {config.discoveryURI}
            </KeyPairItem>
            <KeyPairItem label="Issuer">{config.issuer}</KeyPairItem>
            <KeyPairItem label="Authorization Endpoint" href={config.authorizationEndpoint}>
              {config.authorizationEndpoint}
            </KeyPairItem>
            <KeyPairItem label="Token Endpoint" href={config.tokenEndpoint}>
              {config.tokenEndpoint}
            </KeyPairItem>
            <KeyPairItem label="JWKS URI" href={config.jwksURI}>
              {config.jwksURI}
            </KeyPairItem>
            <KeyPairItem label="Role Mapping">{config.roleMapping}</KeyPairItem>
            <KeyPairItem label="Email Domains">{config.emailDomains?.join(', ')}</KeyPairItem>
            <KeyPairItem
              label="Auth0 Connection ID"
              href={`${auth0Domain}/connections/enterprise/oidc/${config.auth0ConnectionId}/settings`}
            >
              {config.auth0ConnectionId}
            </KeyPairItem>
            {config.auth0ApplicationId && (
              <KeyPairItem
                label="Auth0 Application ID"
                href={`${auth0Domain}/applications/${config.auth0ApplicationId}/settings`}
              >
                {config.auth0ApplicationId}
              </KeyPairItem>
            )}
            <KeyPairItem label="Auth0 Organization ID">{orgAuth0Id}</KeyPairItem>
            <KeyPairItem label="Login with org SSO Link" href={loginWithOrgLink}>
              {loginWithOrgLink}
            </KeyPairItem>
          </KeyPairSection>
        </div>
      )}
      <EditSsoConfigModal
        open={editModalOpen}
        closeModal={closeEditModal}
        saveAndClose={editSaveAndClose}
        config={config}
      />
      <DeleteSsoConfigModal
        open={deleteModalOpen}
        closeModal={closeDeleteModal}
        saveAndClose={deleteSaveAndClose}
        ssoConfig={config}
      />
    </>
  );
};

export default SsoConfigTable;
