import { Button } from 'foundation/button';
import { Alert } from 'foundation/alert';
import React, { useEffect, useState } from 'react';
import { useTracking } from 'react-tracking';
import { Dialog } from 'foundation/dialog';
import { Database } from 'entities/database';
import Actions from 'actions';
import * as yup from 'yup';
import { validateYup } from 'utils/validation';
import { DatabaseNameInput } from 'application/create-db/form-essentials';
import { Form } from 'foundation/form-element';

interface ModalProps {
  database: Database;
  open: boolean;
  onSuccess: () => void;
  onFailure: (error: any) => void;
  onClose: () => void;
}

interface Data {
  name: string;
}

const schema = yup.object({
  name: DatabaseNameInput.yupValidator.label('Name'),
});

const validate = (data: Data) => validateYup(schema, data);

const defaults = (database: Database): Data => ({ name: database.Name });

export const RenameDatabaseModal = ({
  database,
  open,
  onSuccess,
  onFailure,
  onClose,
}: ModalProps) => {
  const tracking = useTracking();
  const [data, setData] = useState<Data>(() => defaults(database));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validation, setValidation] = useState(null);

  const handleChange = (name: string) => setData({ name });

  const handleSubmit = async () => {
    tracking.trackEvent({ action: 'edit_db_name_save' });

    if (data.name === database.Name) {
      onSuccess();
      return;
    }

    const errors = validate(data);
    if (errors) {
      setValidation(errors);
      return;
    }

    setLoading(true);

    try {
      await Actions.databases.updateDatabaseName(database.DbId, data.name);
      onSuccess();
    } catch (e) {
      setError(e);
      onFailure(e);
    }

    setLoading(false);
  };

  const resetForm = () => {
    setData(defaults(database));
    setError(null);
    setValidation(null);
    setLoading(false);
  };

  useEffect(() => resetForm(), [database.Name]);

  const handleCancelClick = () => {
    if (loading) return;
    resetForm();
    onClose();
  };

  return (
    <Dialog
      open={open}
      modalProps={{ 'data-testid': 'rename-db-modal' }}
      onClose={handleCancelClick}
    >
      <Dialog.Header>
        Rename <span className="tw-font-light">{database.Name}</span>
      </Dialog.Header>
      <Dialog.Content>
        <Form onSubmit={handleSubmit}>
          <DatabaseNameInput
            value={data.name}
            onChange={handleChange}
            validationError={validation?.name?.message}
            onFocus={e => e.currentTarget.select()}
            autoFocus
          />
        </Form>
        {error && (
          <Alert
            description="Something went wrong. Please try again later"
            type="danger"
            className="tw-mt-2"
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          fill="outlined"
          color="neutral"
          onClick={handleCancelClick}
          disabled={loading}
          className="tw-mr-2"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          loading={loading}
          iconProps={{ style: { marginTop: '-5px' } }}
          data-testid="rename-db-confirm"
          disabled={database.Name === data.name}
        >
          Rename
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
