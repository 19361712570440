import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import {
  Tier,
  CloudProvider,
  friendlyCloudProviderNameMap,
  findDatabaseSize,
} from 'entities/database';
import { validateYup, Validation } from 'utils/validation';
import { CreateDatabaseFormData, CreateDatabaseFormOptions } from './form-data';
import { DatabaseNameInput, RegionSelect, VersionSelect } from './form-essentials';
import { useNeo4jVersionOptions, useRegionOptions } from './hooks';
import SizeEstimateResult from './form-estimate-result';

import {
  availableRegionsForCloudProviderAndTier,
  availableRegionsForTier,
  getAvailableCloudProviders,
  tierDisplayName,
} from 'entities/tenant';
import { useSession } from 'store';
import { Alert, Accordion, Button, Divider, TextLink } from 'foundation';
import './form-self-serve.css';
import track, { useTracking } from 'react-tracking';
import { useDarkTheme } from 'utils/hooks';
import { CloudProviderCard } from './cloud-provider-card';
import {
  formatSizeString,
  getDefaultSelfServeDatabaseName,
  scrollToFirstError,
} from 'components/utils';
import DatabaseSizeEstimator, {
  Data as SizeEstimatorData,
  defaults as sizeEstimatorDefaults,
  validate as validateSizeEstimatorData,
} from './form-size-estimator';
import { SizeEstimate } from 'types/size-estimate';
import { getErrorMessage } from 'remote/error-handler/error-messages';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import actions from 'actions';
import logger from 'logger';
import { getDefaultVersion } from 'utils/neo4j-versions';

export const defaults = (options: CreateDatabaseFormOptions): CreateDatabaseFormData => {
  const availableRegions = availableRegionsForTier(options.providerConfigs, Tier.GDS);
  const region = availableRegions[0]?.name;
  const availableCloudProviders = getAvailableCloudProviders(
    options.providerConfigs,
    options.tenant.tenantType
  );
  return {
    name: getDefaultSelfServeDatabaseName(),
    region,
    version: undefined,
    size: undefined,
    tier: Tier.GDS,
    cloudProvider: availableCloudProviders.length === 1 ? availableCloudProviders[0] : undefined,
  };
};

const schema = yup.object({
  name: DatabaseNameInput.yupValidator.label('Name'),
  version: yup
    .string()
    .required()
    .label('Version'),
  size: yup.object({ memory: yup.string().required() }).required(),
  region: yup
    .string()
    .required()
    .label('Region'),
  cloudProvider: yup.string().required(),
});

export const validate = (
  data: CreateDatabaseFormData,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => validateYup(schema, data, onlyRequired || false);

interface Props {
  data: CreateDatabaseFormData;
  options: CreateDatabaseFormOptions;
  onChange: (data: CreateDatabaseFormData) => any;
  validation: Validation<CreateDatabaseFormData>;
  disabled?: boolean;
  nodeCount?: number;
  relationshipCount?: number;
  cloning?: boolean;
}

const GDSFields = ({
  data,
  options,
  onChange,
  validation,
  disabled,
  nodeCount,
  relationshipCount,
}: Props) => {
  const session = useSession();
  const tracking = useTracking();
  const neo4jVersionOptions = useNeo4jVersionOptions(options.neo4jVersions);
  const cloudProviders = getAvailableCloudProviders(
    options.providerConfigs,
    options.tenant.tenantType
  ).filter(cp => {
    if (cp === CloudProvider.AWS) return true;
    if (session.featureToggles.enable_azure_gds && cp === CloudProvider.AZURE) return true;
    if (cp === CloudProvider.GCP) return true;
    return false;
  });

  const regions = useMemo(() => {
    return data.cloudProvider
      ? availableRegionsForCloudProviderAndTier(
          options.providerConfigs,
          data.cloudProvider,
          data.tier
        )
      : [];
  }, [data.cloudProvider]);
  const regionOptions = useRegionOptions(regions);
  const [openProvider, setOpenProvider] = useState(true);
  const [openSize, setOpenSize] = useState(true);
  const [openDetails, setOpenDetails] = useState(false);
  const isDarkTheme = useDarkTheme();
  const showGDSVersionSelector = session.featureToggles.enable_gds_version_picker;
  const availableSizes = options.databaseSizes[Tier.GDS];
  const initialSizeEstimation: SizeEstimatorData = sizeEstimatorDefaults(
    nodeCount,
    relationshipCount
  );
  const [sizeEstimate, setSizeEstimate] = useState<SizeEstimate>(null);
  const [estimateError, setEstimateError] = useState<string>(null);
  const [isCalculatingEstimate, setIsCalculatingEstimate] = useState(false);

  const [sizeEstimatorData, setSizeEstimatorData] = useState(initialSizeEstimation);
  const [sizeEstimatorValidation, setSizeEstimatorValidation] = useState<
    Validation<SizeEstimatorData>
  >(null);

  const handleSizeChange = (memory: string) => {
    const exactMatch = findDatabaseSize(availableSizes, memory);
    const size = exactMatch || availableSizes[0];
    onChange({ ...data, size });
  };

  const handleVersionChange = (version: string) => onChange({ ...data, version });

  const calculateEstimate = async e => {
    e.preventDefault();

    tracking.trackEvent({
      action: 'ESTIMATE_REQUEST',
      properties: {
        ...sizeEstimatorData,
      },
    });

    onChange({ ...data, size: undefined });
    const validationErrors = validateSizeEstimatorData(sizeEstimatorData);
    setSizeEstimatorValidation(validationErrors);
    setSizeEstimate(null);

    if (validationErrors) {
      scrollToFirstError();
      return;
    }

    setIsCalculatingEstimate(true);
    setEstimateError(null);

    try {
      const estimate = await actions.estimations.estimateDatabaseSize(
        sizeEstimatorData.nodeCount > 1 ? sizeEstimatorData.nodeCount : 2,
        sizeEstimatorData.relationshipCount ?? 2,
        sizeEstimatorData.categories || [],
        data.tier
      );
      setSizeEstimate(estimate);
      handleSizeChange(estimate.recommended_size);
      setIsCalculatingEstimate(false);

      tracking.trackEvent({
        action: 'ESTIMATE_REQUEST_COMPLETE',
        properties: {
          ...sizeEstimatorData,
        },
      });
    } catch (err) {
      logger.error('Database size estimation request failed:', err);
      tracking.trackEvent({
        action: 'ESTIMATE_REQUEST_FAIL',
        properties: {
          ...sizeEstimatorData,
        },
      });
      if (err instanceof ApiClientRequestError) {
        setEstimateError(getErrorMessage(err.response.status, err.reason));
      } else {
        setEstimateError(getErrorMessage(500, null));
      }
      setIsCalculatingEstimate(false);
    }
  };

  const sizeEstimatorMissingRequiredFields = useMemo(
    () => !!validateSizeEstimatorData(sizeEstimatorData, { onlyRequired: true }),
    [sizeEstimatorData]
  );

  const regionPlaceholder = data.cloudProvider
    ? !regions.length
      ? 'There are currently no available regions...'
      : 'Select...'
    : 'Select a cloud provider to see available regions...';

  const handleSizeEstimatorDataChange = (newData: SizeEstimatorData) => {
    setSizeEstimatorData(newData);
  };

  const handleRegionChange = (region: string) => {
    onChange({ ...data, region });
  };

  const handleDatabaseNameChange = (name: string) => onChange({ ...data, name });

  const handleSelectCloudProvider = (event?: React.MouseEvent, cloudProvider?: CloudProvider) => {
    if (cloudProviders.length === 1) return false;

    if (!cloudProvider || data.cloudProvider === cloudProvider) {
      onChange({
        ...data,
        cloudProvider: undefined,
        region: undefined,
        version: undefined,
      });
      return false;
    }

    onChange({
      ...data,
      cloudProvider,
      region: availableRegionsForCloudProviderAndTier(
        options.providerConfigs,
        cloudProvider,
        data.tier
      )[0]?.name,
      version: getDefaultVersion(options.providerConfigs, cloudProvider, Tier.GDS),
    });
  };

  useEffect(() => {
    if (cloudProviders.length === 1) {
      onChange({
        ...data,
        cloudProvider: cloudProviders[0],
        region: availableRegionsForCloudProviderAndTier(
          options.providerConfigs,
          cloudProviders[0],
          data.tier
        )[0]?.name,
        version: getDefaultVersion(options.providerConfigs, cloudProviders[0], Tier.GDS),
      });
    }
  }, []);

  useEffect(() => {
    setOpenDetails(!!data.size || !!data.version);
  }, [data.size, data.version]);

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-6">
        <Accordion
          header={
            <div className="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center">
              Cloud provider and region
              {data.cloudProvider && (
                <div className="tw-flex n-body-medium tw-text-palette-neutral-text-weaker tw-gap-2 tw-items-center tw-mr-2">
                  {data.region}, {friendlyCloudProviderNameMap[data.cloudProvider]}
                </div>
              )}
            </div>
          }
          open={openProvider}
          onOpen={() => setOpenProvider(!openProvider)}
          position="left"
          data-testid="accordion-cloud-provider"
        >
          <div className="tw-flex tw-flex-col tw-gap-6">
            <div className="n-subheading-medium tw-text-palette-neutral-text-weaker">Provider</div>
            <div className="tw-flex tw-flex-row tw-gap-6" role="radiogroup">
              {cloudProviders.includes(CloudProvider.AZURE) && (
                <CloudProviderCard
                  providerIcon="azure"
                  selected={data.cloudProvider === CloudProvider.AZURE}
                  onSelect={e => handleSelectCloudProvider(e, CloudProvider.AZURE)}
                  data-testid="cloud-provider-azure-card"
                  aria-label="Azure"
                />
              )}
              {cloudProviders.includes(CloudProvider.GCP) && (
                <CloudProviderCard
                  providerIcon="gcp"
                  selected={data.cloudProvider === CloudProvider.GCP}
                  onSelect={e => handleSelectCloudProvider(e, CloudProvider.GCP)}
                  data-testid="cloud-provider-gcp-card"
                  aria-label="GCP"
                />
              )}
              {cloudProviders.includes(CloudProvider.AWS) && (
                <CloudProviderCard
                  providerIcon={isDarkTheme ? 'aws-dark' : 'aws'}
                  selected={data.cloudProvider === CloudProvider.AWS}
                  onSelect={e => handleSelectCloudProvider(e, CloudProvider.AWS)}
                  data-testid="cloud-provider-aws-card"
                  aria-label="AWS"
                />
              )}
            </div>
            <div className="region">
              <RegionSelect
                placeholder={regionPlaceholder}
                value={data.region}
                options={regionOptions}
                onChange={handleRegionChange}
                cloudProvider={data.cloudProvider}
                validationError={validation?.region?.message}
                styles={{
                  input: provided => ({
                    ...provided,
                    visibility: openProvider ? 'visible' : 'hidden',
                    color: 'rgb(var(--theme-palette-neutral-text-default))',
                    lineHeight: '24px',
                  }),
                }}
              />
              <label className="n-body-small tw-text-palette-neutral-text-weak tw-inline-block tw-mt-2">
                Need a different region?{' '}
                <TextLink href="https://aura.feedback.neo4j.com/auradb-regions" externalLink>
                  Let us know!
                </TextLink>
              </label>
            </div>
          </div>
        </Accordion>
        <Accordion
          position="left"
          header="Graph size & algorithms"
          open={openSize}
          onOpen={() => setOpenSize(!openSize)}
          data-testid="accordion-size-picker"
        >
          <DatabaseSizeEstimator
            tier={data.tier}
            data={sizeEstimatorData}
            validation={sizeEstimatorValidation}
            onChange={handleSizeEstimatorDataChange}
          />

          {estimateError && (
            <Alert type="danger" data-testid="estimation-error" className="tw-mt-2">
              {estimateError}
            </Alert>
          )}

          <Divider hidden />

          <div className="tw-mx-auto tw-w-fit">
            <Button
              onClick={calculateEstimate}
              disabled={sizeEstimatorMissingRequiredFields || isCalculatingEstimate}
              loading={isCalculatingEstimate}
              data-testid="button-estimate"
            >
              {isCalculatingEstimate ? 'Calculating...' : 'Calculate Estimate'}
            </Button>
          </div>
        </Accordion>
        <Accordion
          position="left"
          header="Instance details"
          open={openDetails}
          onOpen={() => setOpenDetails(!openDetails)}
          data-testid="accordion-details"
          disabled={!data.size || !data.version}
        >
          <div
            className="tw-flex tw-flex-col tw-mt-4 tw-mb-6 tw-gap-4"
            data-testid="create-professional-db"
          >
            <div>
              <DatabaseNameInput
                value={data.name || ''}
                onChange={handleDatabaseNameChange}
                validationError={validation?.name?.message}
                disabled={disabled}
              />
            </div>
            {showGDSVersionSelector && (
              <div>
                <VersionSelect
                  value={data.version}
                  options={neo4jVersionOptions}
                  onChange={handleVersionChange}
                  validationError={validation?.version?.message}
                />
              </div>
            )}
          </div>
        </Accordion>
        {data.size && <SizeEstimateResult size={data.size} tier={data.tier} />}
        {sizeEstimate?.did_exceed_maximum && (
          <Alert type="warning" data-testid="did-exceed-max-msg" className="tw-mt-2">
            <p>
              One or more of your chosen algorithms may need &gt;
              {formatSizeString(sizeEstimate.recommended_size)} RAM to run efficiently.
            </p>
            <p>
              We have larger instances available in{' '}
              <a href="https://neo4j.com/pricing/#graph-data-science">
                {tierDisplayName(options.tenant, Tier.AURA_DSE)}
              </a>
              .
            </p>
            <p>
              You can proceed with the maximum size available and{' '}
              <a href="mailto:cloudsales@neo4j.com">contact us</a> to discuss your requirements.
            </p>
          </Alert>
        )}
      </div>
    </>
  );
};

export default track()(GDSFields);
