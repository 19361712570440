import { SideNavigation as NDLSideNavigation, Link, NewTabLink, Backdrop } from 'foundation';
import React, { useCallback, useEffect, useState } from 'react';
import track, { useTracking } from 'react-tracking';
import { useLocation, useSession, usePermissions } from 'store';
import { Product } from 'types/product';
import { PlanType, TenantType } from 'entities/tenant';
import { Action } from 'types/user';
import { NavigationLocation } from 'state/navigation';
import cx from 'classnames';
import { useMediaQuery } from 'utils/hooks';
import { breakpoints } from 'utils/breakpoints';
import { isLogForwardingEnabled } from 'entities/log-forwarding';
import { isCmekEnabled } from 'entities/encryption-keys';
import { isTrafficConfigEnabled } from 'entities/traffic-config';
import { isMetricsIntegrationEnabled } from 'entities/metrics-integration';
import { useInOrgSettingsPage } from 'components/pages/organization-settings/helpers';

interface SideNavigationProps {
  sideNavVisible: boolean;
  onSidenavVisibleChange: (expanded: boolean) => void;
}

interface SupportToolsSideNavProps {
  location: NavigationLocation;
  onClickMobile: () => any;
}

const SupportToolsSideNav = ({ location, onClickMobile }: SupportToolsSideNavProps) => {
  const permission = usePermissions();
  const canTransferFrom = permission.allow(Action.TRANSFER, 'namespaces/*/databases/*');
  const canTransferTo = permission.allow(Action.TRANSFER, 'namespaces/*/databases');

  const canTransferInstance = canTransferFrom && canTransferTo;
  const canAuthorizeUsers = permission.allow(Action.CREATE, 'authorized-users');
  const canManageUsers = permission.allow(Action.READ, 'users');
  const canCreateTenants = permission.allow(Action.CREATE, 'namespaces');
  const canLookupTenants = permission.allow(Action.READ, 'namespaces');
  const canLookupDatabases = permission.allow(Action.READ, 'namespaces/*/databases/*');
  const canCreateOrganizations = permission.allow(Action.CREATE, 'organizations');
  const canListOrganizations = permission.allow(Action.READ, 'organizations');
  const canViewFeatureToggles = permission.allow(Action.READ, 'feature-toggles/overrides');

  const canManageSsoConfigs =
    permission.allow(Action.CREATE, 'sso-configs') &&
    permission.allow(Action.READ, 'sso-configs/*');

  const canSeeSupportTools =
    canCreateTenants ||
    canLookupTenants ||
    canLookupDatabases ||
    canManageUsers ||
    canTransferInstance ||
    canAuthorizeUsers ||
    canManageSsoConfigs;

  if (!canSeeSupportTools) {
    return null;
  }

  return (
    <NDLSideNavigation.List>
      <NDLSideNavigation.GroupHeader
        data-appcues-id="support-tools"
        data-testid="sidenav-support-tools"
      >
        Internal Support Tools
      </NDLSideNavigation.GroupHeader>
      <NDLSideNavigation.List>
        {canCreateOrganizations ? (
          <NDLSideNavigation.Item
            onClick={onClickMobile}
            as={Link}
            href="#admin/organizations/create"
            selected={location.hash === '#admin/organizations/create'}
          >
            Create Organization
          </NDLSideNavigation.Item>
        ) : null}
        {canListOrganizations ? (
          <NDLSideNavigation.Item
            onClick={onClickMobile}
            as={Link}
            href="#admin/organizations"
            selected={location.hash === '#admin/organizations'}
          >
            Lookup Organization
          </NDLSideNavigation.Item>
        ) : null}
        {canCreateTenants ? (
          <NDLSideNavigation.Item
            onClick={onClickMobile}
            as={Link}
            href="#admin/tenants/create"
            selected={location.hash === '#admin/tenants/create'}
          >
            Create Tenant
          </NDLSideNavigation.Item>
        ) : null}

        {canLookupTenants ? (
          <NDLSideNavigation.Item
            onClick={onClickMobile}
            as={Link}
            href="#admin/tenants"
            selected={location.hash === '#admin/tenants'}
          >
            Lookup Tenant
          </NDLSideNavigation.Item>
        ) : null}

        {canLookupDatabases ? (
          <NDLSideNavigation.Item
            onClick={onClickMobile}
            as={Link}
            href="#admin/databases"
            selected={location.hash === '#admin/databases'}
          >
            Lookup Instance
          </NDLSideNavigation.Item>
        ) : null}

        {canManageUsers ? (
          <NDLSideNavigation.Item
            onClick={onClickMobile}
            as={Link}
            href="#admin/users"
            selected={location.hash === '#admin/users'}
          >
            Lookup Users
          </NDLSideNavigation.Item>
        ) : null}

        {canViewFeatureToggles ? (
          <NDLSideNavigation.Item
            onClick={onClickMobile}
            as={Link}
            href="#admin/feature-toggles"
            selected={location.hash === '#admin/feature-toggles'}
          >
            Feature Toggles Overview
          </NDLSideNavigation.Item>
        ) : null}

        {canTransferInstance ? (
          <NDLSideNavigation.Item
            onClick={onClickMobile}
            as={Link}
            href="#admin/transfer-to-tenant"
            selected={location.hash === '#admin/transfer-to-tenant'}
          >
            Transfer Instance to Tenant
          </NDLSideNavigation.Item>
        ) : null}

        {canTransferInstance ? (
          <NDLSideNavigation.Item
            onClick={onClickMobile}
            as={Link}
            href="#admin/transfer-instance"
            selected={location.hash === '#admin/transfer-instance'}
          >
            Transfer Instance to User
          </NDLSideNavigation.Item>
        ) : null}

        {canAuthorizeUsers ? (
          <NDLSideNavigation.Item
            onClick={onClickMobile}
            as={Link}
            href="#admin/authorize-users"
            selected={location.hash === '#admin/authorize-users'}
          >
            Authorize Users
          </NDLSideNavigation.Item>
        ) : null}

        {canManageUsers ? (
          <NDLSideNavigation.Item
            onClick={onClickMobile}
            as={Link}
            href="#admin/oauth"
            selected={location.hash === '#admin/oauth'}
          >
            Lookup OAuth Client
          </NDLSideNavigation.Item>
        ) : null}

        {canManageSsoConfigs ? (
          <NDLSideNavigation.Item
            onClick={onClickMobile}
            as={Link}
            href="#admin/sso-configs"
            selected={location.hash === '#admin/sso-configs'}
          >
            Manage SSO Configs
          </NDLSideNavigation.Item>
        ) : null}
      </NDLSideNavigation.List>
    </NDLSideNavigation.List>
  );
};

const SideNavigation = ({ sideNavVisible, onSidenavVisibleChange }: SideNavigationProps) => {
  const session = useSession();
  const tracking = useTracking();
  const location = useLocation();
  const aboveMobileWidth = useMediaQuery(breakpoints.lg);
  const onClickMobile = () => {
    if (!aboveMobileWidth) onSidenavVisibleChange(false);
  };
  const inOrgSettingsPage = useInOrgSettingsPage();

  const [hideSidenav, setHideSidenav] = useState(
    location.hash.startsWith('#settings') || inOrgSettingsPage
  );

  const showAuraDS = session?.tenant?.tenantType !== TenantType.MARKETPLACE_AZURE;
  const showGdsSessions = session?.tenant?.capabilities.gds_sessions === true;

  useEffect(() => {
    if (location.hash.startsWith('#settings') || inOrgSettingsPage) {
      setHideSidenav(true);
    } else {
      setHideSidenav(false);
    }
  }, [location.hash]);

  const isProductAndHash = useCallback(
    (product, hash) => {
      const value = location.hash === hash && product === session.product;
      return value;
    },
    [location, session]
  );

  const classes = cx('lg:tw-relative tw-absolute tw-z-20', {
    'tw-hidden': hideSidenav,
  });

  const showOperationsSection =
    isLogForwardingEnabled(session) || isMetricsIntegrationEnabled(session);
  const showSecuritySection =
    session.featureToggles.enable_teams_management ||
    isCmekEnabled(session) ||
    isTrafficConfigEnabled(session);

  return (
    <>
      <NDLSideNavigation
        expanded={sideNavVisible}
        onExpandedChange={onSidenavVisibleChange}
        className={classes}
      >
        <NDLSideNavigation.List>
          <NDLSideNavigation.GroupHeader>AuraDB</NDLSideNavigation.GroupHeader>
          <NDLSideNavigation.List>
            <NDLSideNavigation.Item
              onClick={onClickMobile}
              as={Link}
              href="?product=aura-db#databases"
              data-testid="auradb-instances"
              selected={
                isProductAndHash(Product.AURA_DB, '#databases') ||
                isProductAndHash(Product.AURA_DB, '') ||
                (location.hash.startsWith('#databases') && Product.AURA_DB === session.product)
              }
            >
              Instances
            </NDLSideNavigation.Item>
            <NDLSideNavigation.Item
              as={NewTabLink}
              data-testid="auradb-connect"
              href="https://neo4j.com/docs/create-applications/"
              onClick={() => {
                tracking.trackEvent({
                  action: 'connect_link',
                  properties: { event_label: 'clicked' },
                });
                onClickMobile();
              }}
            >
              Connect
            </NDLSideNavigation.Item>
            {session.planType === PlanType.ENTERPRISE && (
              <NDLSideNavigation.Item
                as={NewTabLink}
                href="https://neo4j.com/docs/aura/auradb/importing/import-database/"
                data-testid="auradb-import"
                data-appcues-id="import-instructions-navigation"
                onClick={() => {
                  tracking.trackEvent({
                    action: 'import_link',
                    properties: { event_label: 'clicked' },
                  });
                  onClickMobile();
                }}
              >
                Import
              </NDLSideNavigation.Item>
            )}
          </NDLSideNavigation.List>
        </NDLSideNavigation.List>

        {showAuraDS && (
          <NDLSideNavigation.List>
            <NDLSideNavigation.GroupHeader>AuraDS</NDLSideNavigation.GroupHeader>
            <NDLSideNavigation.List>
              <NDLSideNavigation.Item
                onClick={onClickMobile}
                as={Link}
                href="/?product=aura-ds#databases"
                data-testid="aurads-instances"
                selected={
                  isProductAndHash(Product.AURA_DS, '#databases') ||
                  isProductAndHash(Product.AURA_DS, '') ||
                  (location.hash.startsWith('#databases') && Product.AURA_DS === session.product)
                }
              >
                Instances
              </NDLSideNavigation.Item>
              <NDLSideNavigation.Item
                as={NewTabLink}
                data-testid="aurads-getting-started"
                href="https://neo4j.com/docs/aura/aurads/connecting/"
                data-appcues-id="connecting-aurads-documentation-navigation"
                onClick={() => {
                  tracking.trackEvent({
                    action: 'aurads_documentation_connecting_link',
                    properties: { event_label: 'clicked' },
                  });
                  onClickMobile();
                }}
              >
                Getting Started
              </NDLSideNavigation.Item>
              {session.planType === PlanType.ENTERPRISE && (
                <NDLSideNavigation.Item
                  as={NewTabLink}
                  data-testid="aurads-import"
                  href="https://neo4j.com/docs/aura/aurads/importing-data/"
                  data-appcues-id="import-instructions-navigation"
                  onClick={() => {
                    tracking.trackEvent({
                      action: 'import_link',
                      properties: { event_label: 'clicked' },
                    });
                    onClickMobile();
                  }}
                >
                  Import
                </NDLSideNavigation.Item>
              )}
              {showGdsSessions && (
                <NDLSideNavigation.Item
                  as={Link}
                  data-testid="gds-sessions"
                  href="/?#sessions"
                  onClick={onClickMobile}
                >
                  Sessions
                </NDLSideNavigation.Item>
              )}
            </NDLSideNavigation.List>
          </NDLSideNavigation.List>
        )}

        {showOperationsSection && (
          <NDLSideNavigation.List>
            <NDLSideNavigation.GroupHeader>Operations</NDLSideNavigation.GroupHeader>
            <NDLSideNavigation.List>
              {isLogForwardingEnabled(session) && (
                <NDLSideNavigation.Item
                  onClick={onClickMobile}
                  as={Link}
                  href="#log-forwarding"
                  data-appcues-id="log-forwarding-navigation"
                  data-testid="auradb-log-forwarding"
                  selected={location.hash === '#log-forwarding'}
                >
                  Log Forwarding
                </NDLSideNavigation.Item>
              )}
              {isMetricsIntegrationEnabled(session) && (
                <NDLSideNavigation.Item
                  onClick={onClickMobile}
                  as={Link}
                  href="#metrics-integration"
                  data-appcues-id="metrics-integration-navigation"
                  data-testid="auradb-metrics-integration"
                  selected={location.hash === '#metrics-integration'}
                >
                  Metrics Integration
                </NDLSideNavigation.Item>
              )}
            </NDLSideNavigation.List>
          </NDLSideNavigation.List>
        )}

        {showSecuritySection && (
          <NDLSideNavigation.List>
            <NDLSideNavigation.GroupHeader>Security</NDLSideNavigation.GroupHeader>
            <NDLSideNavigation.List>
              {session.featureToggles.enable_teams_management && (
                <NDLSideNavigation.Item
                  onClick={onClickMobile}
                  as={Link}
                  href="#user-management"
                  data-appcues-id="teams-navigation"
                  data-testid="sidenav-user-management"
                >
                  User Management
                </NDLSideNavigation.Item>
              )}
              {isCmekEnabled(session) && (
                <NDLSideNavigation.Item
                  onClick={onClickMobile}
                  as={Link}
                  href="#customer-managed-keys"
                  data-appcues-id="cmek-navigation"
                  data-testid="cmek-navigation"
                  selected={location.hash === '#cmek'}
                >
                  Customer Managed Keys
                </NDLSideNavigation.Item>
              )}
              {isTrafficConfigEnabled(session) && (
                <NDLSideNavigation.Item
                  onClick={onClickMobile}
                  as={Link}
                  href="#network"
                  data-appcues-id="network-access-navigation"
                  data-testid="network-access-navigation"
                  selected={location.hash === '#network'}
                >
                  Network Access
                </NDLSideNavigation.Item>
              )}
            </NDLSideNavigation.List>
          </NDLSideNavigation.List>
        )}

        <SupportToolsSideNav location={location} onClickMobile={onClickMobile} />
      </NDLSideNavigation>
      {!aboveMobileWidth && (
        <Backdrop onClick={() => onSidenavVisibleChange(false)} visible={sideNavVisible} />
      )}
    </>
  );
};

export default track()(SideNavigation);
