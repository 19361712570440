import {
  CreateDatabaseResponse,
  Database,
  DatabaseStatus,
  SignedUrlObject,
  Tier,
} from 'entities/database';
import { UseQueryResult, useQuery } from 'react-query';
import ApiClient, { IgnoreErrors } from 'remote/api-client';
import { boltConnectionValues } from 'utils/connection-uri-helper';
import { get as getEncryptionKey } from './encryption-keys';

const list = (tenantId: string, ignore?: IgnoreErrors): Promise<Database[]> =>
  ApiClient.get('/databases')
    .ignoreErrors(ignore)
    .query({ Namespace: tenantId })
    .then(databases => Promise.all(databases.map(db => get(db.DbId, ignore))));

const get = (dbId: string, ignore?: IgnoreErrors): Promise<Database> =>
  ApiClient.get(`/databases/${dbId}`)
    .ignoreErrors(ignore)
    .then(async db => {
      let result = { ...db, ConnectionUri: boltConnectionValues(db.BoltUrl).withDefaultProtocol };
      if (db.EncryptionKeyRef && db.DatabaseStatus !== DatabaseStatus.ENCRYPTION_KEY_NOT_FOUND) {
        let tenantId = db.NamespaceId;
        let key = await getEncryptionKey(tenantId, db.EncryptionKeyRef);
        result.EncryptionKey = key;
      }
      // TODO: remove this hack once we switch to returning MTE tier instead of pretending it's ENTERPRISE.
      // See https://trello.com/c/Kh60EmY6/5499-recognise-mte-as-a-separate-tier
      if (result.Tier === Tier.ENTERPRISE && result.TierDisplayName.includes('Business')) {
        result.Tier = Tier.MTE;
      }
      return result;
    });

interface Parameters {
  Tier: Tier;
  Name: string;
  Region: string;
  Version: string;
  Memory: string;
  SecondariesCount?: number;
  CDCEnrichmentMode?: string;
  CloudProvider: string;
  SourceSnapshot?: {
    DbId: string;
    SnapshotId?: string;
  };
  IsTrial?: boolean;
}

const create = (parameters: Parameters): Promise<CreateDatabaseResponse> => {
  // Explicitly list ALL parameters here. We don't want to accidentally miss something
  // important. If a parameter can at all be made mandatory, please do so - it's safer.
  const error = validatePropsForTier(parameters, validateProperties);
  if (error) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
  return ApiClient.post('/databases')
    .ignoreAllErrors()
    .issue(parameters)
    .promise();
};

const validatePropsForTier = (parameters, fn) => {
  switch (parameters.Tier) {
    case Tier.FREE:
      return fn(parameters, {
        Name: mandatoryProp,
        Memory: mandatoryProp,
        Namespace: optionalProp,
        Version: mandatoryProp,
        Region: mandatoryProp,
        Tier: mandatoryProp,
        CloudProvider: mandatoryProp,
      });
    case Tier.GDS:
    case Tier.PROFESSIONAL:
      return fn(parameters, {
        Name: mandatoryProp,
        Memory: mandatoryProp,
        Version: mandatoryProp,
        Region: mandatoryProp,
        Namespace: optionalProp,
        BillingIdentity: optionalProp,
        Tier: mandatoryProp,
        SourceSnapshot: optionalProp,
        CloudProvider: mandatoryProp,
        IsTrial: optionalProp,
      });
    default: {
      return fn(parameters, {
        Name: mandatoryProp,
        Memory: mandatoryProp,
        Version: mandatoryProp,
        Region: mandatoryProp,
        Namespace: optionalProp,
        BillingIdentity: optionalProp,
        Tier: mandatoryProp,
        SourceSnapshot: optionalProp,
        EncryptionKeyRef: optionalProp,
        CloudProvider: mandatoryProp,
        Confirmed: optionalProp,
      });
    }
  }
};

const validateProperties = (props, propertyValidators) => {
  if (!props) {
    return Error('Missing props to validate');
  }
  if (!propertyValidators) {
    return Error('Missing propertyValidators parameter');
  }
  const unexpectedProps = Object.keys(props).filter(key => !propertyValidators.hasOwnProperty(key));
  if (unexpectedProps.length > 0) {
    return Error(
      `Received unexpected create DB properties that need validation: ${unexpectedProps}`
    );
  }
  const validationResults = Object.keys(propertyValidators).map(key => {
    const validator = propertyValidators[key];
    return validator(key, props[key]);
  });
  const validationErrors = validationResults.filter(_ => _ !== null);
  if (validationErrors.length > 0) {
    const errorsList = validationErrors.join('; ');
    return Error(`Invalid properties passed while attempting to create DB: ${errorsList}.`);
  }
  return null;
};

const mandatoryProp = (key, value) => {
  if (value === undefined || value === null) {
    return `${key} is required but was ${value}`;
  }
  return null;
};
const optionalProp = () => null;

const destroy = (dbid: string): Promise<Database> =>
  ApiClient.delete(`/databases/${dbid}`)
    .ignore([422, 500])
    .promise();

const update = (dbid: string, parameters): Promise<Database> =>
  ApiClient.patch(`/databases/${dbid}`)
    .issue(parameters)
    .promise();

const getSignedUrlObject = (
  dbid: string,
  size: number,
  fileExtension: string
): Promise<SignedUrlObject> =>
  ApiClient.get(`databases/${dbid}/upload`)
    .ignore(422)
    .query({ size, extension: fileExtension })
    .promise();

const startImportForCloudStorageObject = (dbid: string, object) =>
  ApiClient.post(`databases/${dbid}/upload`)
    .issue({
      CloudStorageObject: object,
    })
    .promise();

const getAvailableSizes = () => ApiClient.get('/databases/sizes').promise();

const overwrite = (targetDbid: string, sourceDbid) =>
  ApiClient.post(`/databases/${targetDbid}/overwrite`)
    .issue({
      SourceDBID: sourceDbid,
    })
    .ignoreAllErrors()
    .promise();

const pause = (dbId: string): Promise<Database> =>
  ApiClient.post(`/databases/${dbId}/pause`).promise();
const resume = (dbId: string): Promise<Database> =>
  ApiClient.post(`/databases/${dbId}/resume`).promise();

const clear = (dbId: string): Promise<{ Message: string }> =>
  ApiClient.post(`/databases/${dbId}/clear`)
    .ignoreAllErrors()
    .promise();

interface TransferProps {
  targetDbid: string;
  targetTenantId?: string;
  targetEmail?: string;
  targetUserId?: string;
}

const transfer = ({ targetDbid, targetTenantId, targetEmail, targetUserId }: TransferProps) =>
  ApiClient.post(`/databases/${targetDbid}/transfer`)
    .issue({
      target_tenant_id: targetTenantId || undefined,
      target_email: targetEmail || undefined,
      target_user_id: targetUserId || undefined,
    })
    .promise();

export const useDatabaseQuery = (tenantId: string): UseQueryResult<Database[]> => {
  const query = async () => {
    const databases = await list(tenantId);
    return databases;
  };

  return useQuery(`databases-${tenantId}`, query, {
    enabled: !!tenantId,
    refetchInterval: 5000,
  });
};

export default {
  getSignedUrlObject,
  startImportForCloudStorageObject,
  list,
  create,
  update,
  getAvailableSizes,
  destroy,
  overwrite,
  get,
  pause,
  resume,
  clear,
  transfer,
};
