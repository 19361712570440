import { useLocation, usePermissions } from 'store';
import { Action } from 'types/user';
import { OrgPageSuffix } from './types';

export const createOrgSettingsPattern = () => {
  const suffixes = Object.values(OrgPageSuffix).join('|');
  return new RegExp(`#organizations/(.+)/(${suffixes})`);
};

export const useCanViewOrganizationMembers = organizationId => {
  const { allow } = usePermissions();

  if (!organizationId) {
    return false;
  }

  return (
    allow(Action.READ, `organizations/${organizationId}/users`) &&
    allow(Action.READ, `organizations/${organizationId}/roles`)
  );
};

export const useInOrgSettingsPage = () => {
  const orgSettingsPattern = createOrgSettingsPattern();
  const location = useLocation();

  const orgSettingsMatch = location.hash.match(orgSettingsPattern);
  const organizationId = orgSettingsMatch ? orgSettingsMatch[1] : undefined;

  return useCanViewOrganizationMembers(organizationId);
};
