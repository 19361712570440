import React from 'react';
import LoadingSpinner from 'foundation/loading-spinner';
import classnames from 'classnames';

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  loading?: boolean;
  plain?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const Page = ({
  children,
  loading = false,
  plain = null,
  fullWidth = false,
  className = null,
  style = {},
  ...props
}: PageProps) => {
  if (loading) {
    return <LoadingSpinner expand />;
  }

  return (
    <div
      className={
        plain
          ? className
          : classnames(
              'tw-bg-palette-neutral-bg-weak',
              'tw-p-4',
              'tw-border',
              'tw-border-solid',
              'tw-rounded-lg',
              'tw-shadow-raised',
              'tw-border-palette-neutral-border-weak',
              className
            )
      }
      style={{ ...(fullWidth ? { maxWidth: 'none', margin: 'none' } : {}), ...style }}
      {...props}
    >
      {children}
    </div>
  );
};

export { Page };
