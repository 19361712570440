import React, { useState } from 'react';
import CreateTenantFormFields, {
  defaults,
  validate,
  CreateTenantFormData,
} from './create-tenant-form-fields';
import { Validation } from 'utils/validation';
import InternalTenantsResource, {
  InternalCreateTenantPayload,
} from 'remote/resources/internal-tenant';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { Button, Alert } from 'foundation';
import { Form } from 'foundation/form-element';
import { InternalTenant, TenantType } from 'entities/tenant';

interface Props {
  lockedOrganizationId?: string;
}

const getErrorContent = (
  err: ApiClientRequestError,
  payload: InternalCreateTenantPayload
): JSX.Element => {
  switch (err.reason) {
    case 'isolation-unit-not-found':
      return (
        <Alert type="danger" data-testid="create-tenant-error-message" className="tw-mt-2">
          {`One of the entered isolation IDs does not match any existing isolation unit: ${err.responseMessage}`}
        </Alert>
      );
    case 'salesforce-cpp-does-not-exist':
      return (
        <Alert type="danger" data-testid="create-tenant-error-message" className="tw-mt-2">
          Salesforce Customer Project Profile {payload.salesforceCppId} does not exist.
        </Alert>
      );
    case 'single-tenancy-isolation-unit-occupied':
      return (
        <Alert type="danger" data-testid="create-tenant-error-message" className="tw-mt-2">
          {/* This message says "Single tenancy isolation unit {id} is occupied" */}
          {err.responseMessage}
        </Alert>
      );
    case 'tenant-exists':
    case 'project-exists':
      return (
        <>
          <Alert type="danger" data-testid="create-tenant-error-message" className="tw-mt-2">
            A tenant with ID <code>{payload.internalName}</code> already exists.
          </Alert>
          <div className="tw-mt-4">
            You may invite users to the existing tenant by using the{' '}
            <a
              href={`#admin/projects/${payload.internalName}/invite`}
              target="_blank"
              rel="noopener noreferrer"
            >
              invite user form
            </a>
            .
          </div>
        </>
      );
    case 'unauthorized':
      return (
        <Alert type="danger" data-testid="create-tenant-error-message" className="tw-mt-2">
          You do not have permission to create tenants.
        </Alert>
      );
    case 'isolation-units-have-same-product':
      return (
        <Alert type="danger" data-testid="create-tenant-error-message" className="tw-mt-2">
          A tenant cannot have two or more isolation units with the same product
        </Alert>
      );
    case 'organization-not-found':
      return (
        <Alert type="danger" data-testid="create-tenant-error-message" className="tw-mt-2">
          A Organization with UUID {payload.organizationId} does not exist.
        </Alert>
      );
    default:
      return (
        <Alert type="danger" data-testid="create-tenant-error-message" className="tw-mt-2">
          Something unexpected went wrong. Please try again or contact the Aura Console team for
          assistance.
        </Alert>
      );
  }
};

const CreateTenantForm = ({ lockedOrganizationId = '' }: Props) => {
  const [data, setData] = useState({ ...defaults(), organizationId: lockedOrganizationId });
  const [validation, setValidation] = useState<Validation<CreateTenantFormData>>(null);
  const [hasFailedValidation, setHasFailedValidation] = useState(false);
  const [createdTenant, setCreatedTenant] = useState<InternalTenant>(null);
  const [responseError, setResponseError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDataChange = (newData: CreateTenantFormData) => {
    setData(newData);

    if (hasFailedValidation) {
      setValidation(validate(newData));
    }
  };

  const handleSubmit = async () => {
    const newValidation = validate(data);
    setResponseError(null);
    if (newValidation) {
      setValidation(newValidation);
      setCreatedTenant(null);
      setHasFailedValidation(true);
    } else {
      const trimmedInternalName = data.internalName.trim();
      const trimmedIsolationIds = data.isolationIds
        .map(id => id.trim())
        .filter(id => id.length > 0);
      const friendlyName = data.friendlyName.trim();
      const salesforceCppId = data.salesforceCppId || undefined;
      const trimmedOrganizationId = data.organizationId.trim();

      setValidation(null);
      setHasFailedValidation(false);
      setLoading(true);

      const payload: InternalCreateTenantPayload = {
        internalName: trimmedInternalName,
        friendlyName,
        tenantType: TenantType.ENTERPRISE,
        isolationIds: trimmedIsolationIds,
        requireMFA: data.requireMFA,
        salesforceCppId,
        minimumNeo4jVersion: data.minimumNeo4jVersion,
        tenantProfile: data.tenantProfile,
        organizationId: trimmedOrganizationId,
      };

      try {
        const tenant = await InternalTenantsResource.createTenant(payload);
        setCreatedTenant(tenant);
        setData(defaults());
      } catch (err) {
        setResponseError(getErrorContent(err, payload));
      }
      setLoading(false);
    }
  };
  return (
    <Form data-testid="create-tenant-form" onSubmit={handleSubmit}>
      <CreateTenantFormFields
        data={data}
        onChange={handleDataChange}
        validation={validation}
        lockedOrganizationId={lockedOrganizationId}
      />
      <Button type="submit" loading={loading} data-testid="create-tenant-submit-button">
        Submit
      </Button>

      {createdTenant && (
        <>
          <Alert type="success" data-testid="create-tenant-success-message" className="tw-mt-2">
            The tenant <code>{createdTenant.internalName}</code> has been created.
          </Alert>
          <div className="tw-mt-4">
            You can invite users to the new tenant by using the{' '}
            <a
              href={`#admin/projects/${createdTenant.id}/invite`}
              target="_blank"
              rel="noopener noreferrer"
            >
              invite user form
            </a>
            .
          </div>
        </>
      )}

      {responseError}
    </Form>
  );
};

export default CreateTenantForm;
