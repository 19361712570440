import React from 'react';
import { DatabaseSizePicker } from 'application/db-sizes';
import { ResizeDatabaseFormData } from './form-data';
import { DatabaseSize, Tier } from 'entities/database';
import { PlanType, Tenant, tierDisplayName } from 'entities/tenant';
import { calcMonthlyCost } from 'application/db-sizes/capacities';
import formatDollars from 'utils/format-dollars';
import { Divider, Alert, Checkbox } from 'foundation';
import { useSession } from 'store';

interface Props {
  data: ResizeDatabaseFormData;
  onChange: (data: ResizeDatabaseFormData) => any;
  tier: Tier;
  sizes: DatabaseSize[];
  cloudProvider: string;
  region: string;
  initialSize: DatabaseSize;
  version: string;
  resizeThresdhold?: number;
}

export const validate = (data: ResizeDatabaseFormData, initialSize?: DatabaseSize) => {
  if (!data.confirmed) return false;
  if (!data.size) return false;
  if (data.size.memory === initialSize?.memory) return false;

  return true;
};

const ResizeDatabaseForm = ({
  onChange,
  data,
  tier,
  cloudProvider,
  region,
  sizes,
  initialSize,
  version,
  resizeThresdhold,
}: Props) => {
  const session = useSession();
  const handleSizeChange = (size: DatabaseSize) => onChange({ ...data, size });
  const handleConfirmChange = (checked: boolean) => onChange({ ...data, confirmed: checked });
  const dataHasChanged = data.size && data.size.memory !== initialSize?.memory;

  const showUnitPricing = session.planType === PlanType.SELF_SERVE;
  const showMonthlyCost = showUnitPricing && [Tier.PROFESSIONAL, Tier.ENTERPRISE].includes(tier);

  return (
    <>
      <Divider hidden />
      <DatabaseSizePicker
        onChange={handleSizeChange}
        value={data.size}
        hidePricing={!showUnitPricing}
        options={sizes}
        cloudProvider={cloudProvider}
        selectedRegion={region}
        hideMonthlyCost={!showMonthlyCost}
        selectedVersion={version}
        initialSize={initialSize}
        resizeThresdhold={resizeThresdhold}
      />
      <Divider hidden />

      {dataHasChanged && (
        <div>
          <PriceDescription
            tenant={session.tenant}
            planType={session.planType}
            tier={tier}
            size={data.size}
          />

          <div className="tw-mt-6">
            <Checkbox
              label="I accept"
              checked={data.confirmed}
              onChange={({ target }) => handleConfirmChange(target.checked)}
              data-testid="pricing-confirmation-check"
            />
          </div>
        </div>
      )}
    </>
  );
};

interface PriceDescriptionProps {
  tenant: Tenant;
  planType: PlanType;
  tier: Tier;
  size: DatabaseSize;
}

const PriceDescription = ({ tenant, planType, tier, size }: PriceDescriptionProps) => {
  if (planType === PlanType.ENTERPRISE) {
    return (
      <Alert
        data-testid="custom-pricing"
        description={`Please refer to your ${tierDisplayName(tenant, tier)} contract for pricing.`}
        type="info"
      />
    );
  }

  if (tier === Tier.GDS) {
    const costPerHour = formatDollars(size.cost_per_hour);

    return (
      <Alert
        data-testid="aurads-pricing"
        description={`You will be charged ${costPerHour} per hour while an instance of this size is running.`}
        type="warning"
      />
    );
  }

  if ([Tier.PROFESSIONAL, Tier.MTE].includes(tier)) {
    const costPerHour = formatDollars(size.cost_per_hour);
    const costPerMonth = formatDollars(calcMonthlyCost(size.cost_per_hour));

    return (
      <Alert data-testid="auradb-pricing" type="warning">
        You will be charged {costPerHour} per hour while an instance of this size is running, and
        continuously running this database for 1 month will cost {costPerMonth} based on a 730 hour
        average month.
      </Alert>
    );
  }

  throw new Error(`No price description is defined for tier ${tier}`);
};

export default ResizeDatabaseForm;
