export enum PlanType {
  SELF_SERVE = 'self_serve',
  ENTERPRISE = 'enterprise',
}

export type OrganizationCapabilities = {
  [key in OrganizationCapabilityKeys]: boolean;
};

export enum OrganizationCapabilityKeys {
  ORGANIZATION_SSO = 'organization_sso',
}
export interface Organization {
  id: string;
  auth0OrgId: string;
  planType: PlanType;
  name: string;
  displayName: string;
  passwordLoginEnabled: boolean;
  googleLoginEnabled: boolean;
  copilotEnabled: boolean;
  capabilities: OrganizationCapabilities;
}
export interface TrialCustomerInfo {
  firstName: string;
  lastName: string;
  jobRole: string;
  jobRoleOther?: string;
  primaryUseCase?: string;
  primaryUseCaseOther?: string;
  companyName: string;
  country: string;
  subdivision?: string;
}
