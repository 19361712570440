import React from 'react';
import { Checkbox, Label } from 'foundation';
import { InviteDetails } from 'types/invite';
import { RoleName } from 'types/user';

const ROLE_TO_FRIENDLY: Record<RoleName, string> = {
  [RoleName.TENANT_ADMIN]: 'Admin',
  [RoleName.TENANT_MEMBER]: 'Member',
  [RoleName.TENANT_VIEWER]: 'Viewer',
  [RoleName.ORGANIZATION_OWNER]: 'Organization Owner',
  [RoleName.ORGANIZATION_ADMIN]: 'Organization Admin',
  [RoleName.ORGANIZATION_MEMBER]: 'Organization Member',
  [RoleName.AURA_API]: 'Aura API',
  [RoleName.CONSOLE_CLI]: 'Console CLI',
  [RoleName.SRE_PORTAL]: 'SRE',
  [RoleName.CUSTOMER_SUPPORT]: 'Support',
  [RoleName.CUSTOMER_SUPPORT_VIEWER]: 'Support (Viewer)',
  [RoleName.TENANT_METRICS_INTEGRATION_READER]: 'Metrics Integration Reader',
};

interface InviteConsentItemProps {
  invite: InviteDetails;
  checked: boolean;
  onCheckedChange: (invite: InviteDetails, checked: boolean) => void;
}

export const InviteConsentItem = ({ invite, checked, onCheckedChange }: InviteConsentItemProps) => {
  const { InvitedBy, Roles, NamespaceId, FriendlyName } = invite;
  const handleChange = ({ target: { checked: isChecked } }) => {
    onCheckedChange(invite, isChecked);
  };

  const roles = Roles.filter(role => role.startsWith('namespace'));
  return (
    <li className="tw-my-6">
      <Checkbox
        checked={checked}
        onChange={handleChange}
        aria-label={`Check invite to ${FriendlyName} from ${InvitedBy}`}
        label={
          <div>
            <div className="tw-flex tw-gap-2 tw-mb-2 tw-items-center">
              <span className="n-body-large tw-font-bold">{FriendlyName}</span>
              {roles.map(role => (
                <Label color="default" fill="outlined" key={role}>
                  {ROLE_TO_FRIENDLY[role]}
                </Label>
              ))}
              <span className="n-body-medium tw-text-palette-neutral-text-weaker">
                Invitation by {InvitedBy}
              </span>
            </div>
            <span className="n-body-medium tw-text-palette-neutral-text-weaker">{NamespaceId}</span>
          </div>
        }
        data-testid={`invite-consent-item-${invite.InviteId}`}
      />
    </li>
  );
};
