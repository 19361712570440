import React, { SyntheticEvent, useState } from 'react';
import track, { useTracking } from 'react-tracking';

import Actions from 'actions';
import DestroyDatabaseModal from './modal';
import { isDeletable, Database, Tier, DatabaseStatus } from 'entities/database';
import { useDatabaseState, usePermissions, useSession } from 'store';
import { Action } from 'types/user';

export interface DeleteDatabaseChildProps {
  onClick: (e: SyntheticEvent) => void;
  disabled: boolean;
  loading: boolean;
  allowDelete: boolean;
}
export interface DeleteDatabaseProps {
  database: Database;
  children: (args: DeleteDatabaseChildProps) => JSX.Element;
}

const DeleteDatabase = ({ database, children }: DeleteDatabaseProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const tracking = useTracking();
  const { databases } = useDatabaseState();
  const session = useSession();
  const { allow } = usePermissions();
  const allowDelete = allow(
    Action.DELETE,
    `namespaces/${session.currentTenant}/databases/${database.DbId}`
  );

  const gdsAvaliable = session.tenant.capabilities.gds_sessions;

  const handleDeleteClicked = (e: SyntheticEvent) => {
    e.preventDefault();

    setOpen(true);
  };

  const handleDelete = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const billingRequired = session.tenant.requiresBilling;
    const runningPaidInstances = databases?.filter(
      db =>
        (db.Tier === Tier.PROFESSIONAL || db.Tier === Tier.GDS) &&
        db.DatabaseStatus !== DatabaseStatus.DESTROYING
    );
    const countInstances = runningPaidInstances?.length === 1;

    tracking.trackEvent({
      action: 'destroy_db',
      properties: {
        dbid: database.DbId,
        last_paid_instance: countInstances && billingRequired ? 'true_delete' : 'false',
      },
    });

    setLoading(true);

    Actions.databases.destroyDatabase(database.DbId).then(res => {
      setLoading(false);
      if (res && res.error) {
        setError(res.error);
      } else {
        setOpen(false);
        setError(null);
        Actions.navigate.push({ hash: '#databases' });
      }
    });
  };

  const handleCancel = () => {
    setLoading(false);
    setError(null);
    setOpen(false);
  };

  const disabled = loading || !isDeletable(database) || !allowDelete;

  return (
    <>
      {children({ onClick: handleDeleteClicked, loading, disabled, allowDelete })}
      <DestroyDatabaseModal
        open={open}
        database={database}
        loading={loading}
        onConfirm={handleDelete}
        onCancel={handleCancel}
        error={error}
        errorHeader="Unable to destroy database"
        gdsAvaliable={gdsAvaliable}
      />
    </>
  );
};

export default track({ page: 'delete-database' })(DeleteDatabase);
