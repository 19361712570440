import React, { useEffect, useState, SyntheticEvent } from 'react';

import DatabaseName from 'application/db-name';
import ConfirmModal, { ConfirmAction } from 'foundation/confirm-modal';
import { Tip } from '@neo4j-ndl/react';
import globals from 'browser/globals';
import { Alert } from 'foundation';
import { Database } from 'entities/database';

interface Props {
  database: Database;
  loading: boolean;
  open: boolean;
  onConfirm: (e: SyntheticEvent) => void;
  onCancel: () => void;
  error?: any;
  errorHeader?: any;
  gdsAvaliable: boolean;
}

const DestroyDatabaseModal = ({
  database,
  loading,
  open,
  onConfirm,
  onCancel,
  error = undefined,
  errorHeader = undefined,
  gdsAvaliable,
}: Props) => {
  const [tipOpen, setTipOpen] = useState(false);
  useEffect(() => {
    if (tipOpen) {
      setTimeout(() => {
        setTipOpen(false);
      }, 2000);
    }
  }, [tipOpen]);
  return (
    <ConfirmModal
      title={
        <span>
          Are you sure you want to destroy <br />
          <Tip isOpen={tipOpen} type="toggletip" allowedPlacements={['right', 'left']}>
            <Tip.Trigger className="tw-cursor-text">
              <i
                onClick={() => {
                  globals.window.navigator.clipboard.writeText(database.Name);
                  if (!tipOpen) {
                    setTipOpen(true);
                  }
                }}
                aria-aria-label="Copy text"
              >
                <DatabaseName Name={database.Name} DbId={database.DbId} />
              </i>
            </Tip.Trigger>
            <Tip.Content isPortaled={false}>Copied to clipboard</Tip.Content>
          </Tip>{' '}
          ?
        </span>
      }
      content={
        <p>
          {gdsAvaliable === true && (
            <div className="tw-pb-4">
              <Alert
                type="warning"
                description="Data Science sessions created from this instance will not be able to
                write back results while the database is deleted. We recommend that you terminate
                all connected Data Science sessions before deleting the instance."
              />
            </div>
          )}
          This is irreversible. We will destroy your instance and all associated snapshots.
        </p>
      }
      negative
      action={ConfirmAction.DESTROY}
      buttonTextLoading="Destroying"
      confirmText={database.Name}
      loading={loading}
      open={open}
      onConfirm={onConfirm}
      onCancel={onCancel}
      error={error}
      errorHeader={errorHeader}
    />
  );
};

export default DestroyDatabaseModal;
