import BaseStore from './base-store';
import { SizeEstimate } from 'types/size-estimate';
import { PartnerAccount, Permission } from 'types/user';
import { Product } from 'types/product';
import { DatabaseSizeMap } from 'entities/database';
import { ProviderConfigs, PlanType, Tenant } from 'entities/tenant';
import { InviteDetails } from 'types/invite';
import { RegionalGDPRComplianceTier } from 'types/user';
import * as browserHelper from 'browser/helpers';

export interface AccountLinkDetails {
  email: string;
  connection: string;
}

export interface BearerTokenDetails {
  token: string;
  ssoOrgId: string;
}

export type SessionState = {
  loggedIn: boolean;
  name: string | null;
  userId: string | null;
  email: string | null;
  picture: string | null;
  sub: string | null;
  partnerAccount?: PartnerAccount;
  planType: PlanType;
  product: Product;
  fetchedFeatureToggles: boolean;
  featureToggles: Record<string, boolean>;
  availableNeo4jVersions: string[];
  currentTenant: string | null;
  estimate: SizeEstimate;
  allowFreeDatabaseCreation: boolean;
  workspaceEnabled: boolean;
  oauthBearerToken?: string;
  emailVerificationRequired?: boolean;
  acceptedTsAndCs?: string;
  databaseSizes: DatabaseSizeMap;
  providerConfigs: ProviderConfigs;
  permissions: Permission[];
  tenant: Tenant;
  tenants: Tenant[];
  tenantNotFound: boolean;
  invites: InviteDetails[];
  hideInviteConsentForm: boolean;
  isMarketplaceRegistration: boolean;
  accountToLink: AccountLinkDetails;
  ssoOrgBearerTokens: Record<string, BearerTokenDetails>;
  marketingChoiceRequired?: RegionalGDPRComplianceTier;
};

class SessionStore extends BaseStore<SessionState> {
  constructor() {
    super('SessionStore');
  }

  initialState() {
    const storageSsoOrgBearerTokens = browserHelper.loadStoredAuthBearerTokens() || {};
    return {
      loggedIn: false,
      name: null,
      userId: null,
      email: null,
      picture: null,
      sub: null,
      partnerAccount: null,
      planType: PlanType.SELF_SERVE,
      product: Product.AURA_DB,
      fetchedFeatureToggles: false,
      featureToggles: {},
      availableNeo4jVersions: [],
      currentTenant: null,
      estimate: null,
      allowFreeDatabaseCreation: false,
      workspaceEnabled: false,
      databaseSizes: {},
      providerConfigs: {},
      permissions: [],
      tenant: null,
      tenants: [],
      tenantNotFound: false,
      invites: [],
      hideInviteConsentForm: false,
      isMarketplaceRegistration: false,
      accountToLink: null,
      ssoOrgBearerTokens: storageSsoOrgBearerTokens,
      marketingChoiceRequired: null,
    };
  }

  loggingIn() {
    this._updateState({
      ...this.state,
    });
  }

  loggedIn({ bearerToken, ssoOrgId, name, email, picture, sub, partnerAccount, currentTenant }) {
    this._updateState({
      ...this.state,
      loggedIn: true,
      oauthBearerToken: bearerToken,
      name,
      email,
      picture,
      sub,
      partnerAccount,
      currentTenant,
      ssoOrgBearerTokens: {
        ...this.state.ssoOrgBearerTokens,
        ...(ssoOrgId && {
          [ssoOrgId]: {
            token: bearerToken,
            ssoOrgId,
          },
        }),
      },
    });

    if (ssoOrgId) browserHelper.storeAuthBearerToken(bearerToken);
  }

  updateSsoOrgBearerToken({ bearerToken, ssoOrgId }) {
    this._updateState({
      ...this.state,
      ssoOrgBearerTokens: {
        ...this.state.ssoOrgBearerTokens,
        [ssoOrgId]: {
          token: bearerToken,
          ssoOrgId,
        },
      },
    });
  }

  setBearerToken(token: string) {
    this._updateState({
      ...this.state,
      oauthBearerToken: token,
    });
  }

  loadCompleted() {
    this._updateState({
      ...this.state,
      emailVerificationRequired: undefined,
    });
  }

  setTenantNotFound() {
    this._updateState({
      ...this.state,
      tenantNotFound: true,
    });
  }

  setAcceptedTsAndCs(value: string) {
    this._updateState({
      ...this.state,
      acceptedTsAndCs: value,
    });
  }

  setMarketingChoiceRequired(value: RegionalGDPRComplianceTier | null) {
    this._updateState({
      ...this.state,
      marketingChoiceRequired: value,
    });
  }

  setSizeEstimate(value: SizeEstimate) {
    this._updateState({
      ...this.state,
      estimate: value,
    });
  }

  setUserDetails({
    userId,
    acceptedTsAndCs,
    workspaceEnabled,
    permissions,
    marketingChoiceRequired,
  }) {
    this._updateState({
      ...this.state,
      userId,
      acceptedTsAndCs,
      workspaceEnabled,
      permissions,
      marketingChoiceRequired,
    });
  }

  setCurrentTenant(currentTenant) {
    this._updateState({
      ...this.state,
      currentTenant,
    });
  }

  // N.B. use via product actions instead of calling this directly
  setProduct(product: Product) {
    this._updateState({
      ...this.state,
      product,
    });
  }

  setWorkspaceEnabled(enabled: boolean) {
    this._updateState({
      ...this.state,
      workspaceEnabled: enabled,
    });
  }

  setFeatureToggles(data: Record<string, boolean>) {
    this._updateState({
      ...this.state,
      featureToggles: data,
      fetchedFeatureToggles: true,
    });
  }

  emailVerificationRequired() {
    this._updateState({
      ...this.state,
      emailVerificationRequired: true,
    });
  }

  setAccountToLink(accountToLink: AccountLinkDetails | null) {
    this._updateState({
      ...this.state,
      accountToLink,
    });
  }

  setDatabaseSizes(databaseSizes: DatabaseSizeMap) {
    this._updateState({
      ...this.state,
      databaseSizes,
    });
  }

  setTenant(tenant: Tenant) {
    this._updateState({
      ...this.state,
      tenant,
      planType: tenant.planType,
      providerConfigs: tenant.providerConfigs,
      availableNeo4jVersions: tenant.availableNeo4jVersions,
      allowFreeDatabaseCreation: tenant.allowFreeDatabaseCreation,
    });
  }

  setIsMarketplaceRegistration(isRegistration: boolean) {
    this._updateState({ ...this.state, isMarketplaceRegistration: isRegistration });
  }

  setTenants(tenants: Tenant[]) {
    this._updateState({
      ...this.state,
      tenants,
    });
  }

  setInvites(invites: InviteDetails[]) {
    this._updateState({
      ...this.state,
      invites,
    });
  }

  setHideInviteConsentForm(hideInviteConsentForm: boolean) {
    this._updateState({
      ...this.state,
      hideInviteConsentForm,
    });
  }

  loggedOut() {
    this.resetState();
  }

  _redactFilter(stateForLogging) {
    return this._redactField(stateForLogging, 'oauthBearerToken');
  }
}

export default SessionStore;
