import React, { useCallback, useState } from 'react';
import actions from 'actions';
import { Switch, FormSelect, Alert, TextLink } from 'components/foundation';
import { useSession } from 'store';
import { useTracking } from 'react-tracking';
import { THEME_KEY, Theme } from 'utils/themes';
import { useLocalStorage } from 'utils/hooks';
import './preferences.css';
import { TenantType } from 'entities/tenant';

const PreferencePage = () => {
  const tracking = useTracking();
  const session = useSession();
  const [theme, setTheme] = useLocalStorage(THEME_KEY, Theme.SYSTEM_PREFERENCE);
  // We use a local state here to make the UI faster. If we use the session variable
  // the UI becomes slow.
  const [workspaceEnabled, setWorkspaceEnabled] = useState(session.workspaceEnabled);
  const handleWorkspaceSwitchClick = useCallback(
    ({ target: { checked } }) => {
      const newValue = checked;
      tracking.trackEvent(newValue ? 'ENABLE_WORKSPACE' : 'DISABLE_WORKSPACE');
      actions.userDetails.updateWorkspaceEnabled(newValue);
      setWorkspaceEnabled(newValue);
    },
    [session.workspaceEnabled]
  );

  const handleThemeChange = e => {
    setTheme(e.value);
    // A storage event isn't sent when inside the same window so
    // we need to send this event inorder for the useDarkTheme hook to re-run.
    window.dispatchEvent(new Event('storage'));
  };

  const isEnterpriseTenant = session.tenant.tenantType === TenantType.ENTERPRISE;

  return (
    <div className="tw-ml-4">
      <h4 className="tw-mb-5">Preferences</h4>
      <ul className="preference-items tw-bg-palette-neutral-bg-weak tw-rounded-2xl tw-h-auto tw-p-4 tw-gap-2">
        <PreferenceItem
          title="Enable workspace"
          description={
            <>
              <p>Experience our user tools in a single app.</p>
              {isEnterpriseTenant && (
                <Alert
                  type="warning"
                  icon
                  id="workspace-alert"
                  className="tw-mt-5"
                  data-testid="workspace-enterprise-alert"
                >
                  <p className="tw-text-sm">
                    Some additional configuration may be required if you use SSO or VPC Private
                    Link, please{' '}
                    <TextLink href="https://support.neo4j.com/s/" externalLink rel="noreferrer">
                      raise a support ticket
                    </TextLink>
                    .
                  </p>
                </Alert>
              )}
            </>
          }
          action={
            <Switch
              data-testid="workspace-switch"
              onChange={handleWorkspaceSwitchClick}
              checked={workspaceEnabled}
              aria-label="Enable Workspace"
            />
          }
          height={isEnterpriseTenant ? '40' : '24'}
        />
        <PreferenceItem
          title={
            <div className="tw-flex tw-items-center tw-gap-2">
              <div className="tw-flex tw-flex-nowrap tw-items-center">Dark Mode</div>
            </div>
          }
          description="Experience console with a darker theme."
          action={
            <div className="tw-self-center tw-w-48">
              <FormSelect
                onChange={handleThemeChange}
                value={theme}
                options={[
                  {
                    key: 'system_preference',
                    value: Theme.SYSTEM_PREFERENCE,
                    label: 'System preference',
                  },
                  { key: 'dark', value: Theme.DARK, label: 'Dark' },
                  { key: 'light', value: Theme.LIGHT, label: 'Light' },
                ]}
                aria-label="Toggle theme"
                fluid
              />
            </div>
          }
        />
      </ul>
    </div>
  );
};

interface ItemProps {
  title: React.ReactNode;
  description?: string | any;
  action: React.ReactNode;
  height?: string;
}

const PreferenceItem = ({ title, description, action, height = '24' }: ItemProps) => {
  return (
    <li className={`preference-item tw-flex tw-items-center tw-p-4 tw-h-${height}`}>
      <div className="n-body-large tw-flex tw-flex-col tw-gap-1">
        <div className="tw-text-palette-neutral-text-default tw-truncate">{title}</div>
        <div className="tw-text-palette-neutral-text-weak">{description}</div>
      </div>
      <div className="tw-grow tw-mr-4 tw-flex tw-flex-row-reverse">{action}</div>
    </li>
  );
};

export default PreferencePage;
