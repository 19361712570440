import React, {
  ReactNode,
  createContext,
  memo,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import globals from 'browser/globals';
import Actions from 'actions';

import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import countryList from './country-list';
import { useSession } from 'store';
import {
  Alert,
  Button,
  Checkbox,
  Form,
  FormInput,
  FormSelect,
  FormSelectProps,
  Tip,
} from 'foundation';
import './billing.css';
import { useDarkTheme } from 'utils/hooks';
import { Validation, validateYup } from 'utils/validation';
import * as yup from 'yup';
import { BillingAddress } from 'types/customer';
import Icon from 'components/ui/icons';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

interface BillingFormData {
  address: BillingAddress;
  serviceAddress?: BillingAddress;
  companyName?: string;
  name: string;
  email: string;
}

const stripePayloadWithServiceAddress = (formData: BillingFormData, email: string) => {
  const customerAddress = formData.address;

  const serviceAddress = {
    name: formData.name,
    address: formData.serviceAddress,
  };

  const payload = {
    ...formData.address,
    address: customerAddress,
    shipping: serviceAddress,
    name: formData.companyName,
    email,
  };

  return payload;
};

const stripePayloadWithoutServiceAddress = (formData: BillingFormData, email: string) => {
  const customerAddress = formData.address;
  const name = formData.companyName;
  const shipping = {
    name: formData.name,
    address: {
      ...customerAddress,
    },
  };

  const payload = {
    ...formData.address, // is saved on the card entity in stripe
    address: customerAddress, // is saved on the customer
    shipping, // is saved on the customer
    name, // is saved on the card and customer
    email, // is saved on the customer
  };

  return payload;
};

export const useStripeSubmit = () => {
  // Following three must be curried, otherwise Stripe shits itself.
  const elements = useElements();
  const stripe = useStripe();
  const session = useSession();

  return async (billingFormData: BillingFormData, isServiceAddress = true) => {
    let billingDetails;
    if (isServiceAddress) {
      billingDetails = stripePayloadWithoutServiceAddress(billingFormData, session.email);
    } else {
      billingDetails = stripePayloadWithServiceAddress(billingFormData, session.email);
    }

    const cardElement = elements.getElement(CardElement);

    // We override name here with the cardholders name since this creates the token for the card element.
    // We then update the customers name with company name which differs from the cardholder (shipping name).
    const payload = await stripe.createToken(cardElement, {
      ...billingDetails,
      name: billingFormData.name,
      address: undefined,
      shipping: undefined,
    });

    if (payload.error) {
      throw Error(payload.error.message);
    }

    let customerPromise: Promise<any>;
    if (session.tenant.hasBilling) {
      customerPromise = Actions.customer.addCustomerCard(session.tenant.id, payload);
    } else {
      customerPromise = Actions.customer.createCustomer(session.tenant.id, payload);
    }

    let result = await customerPromise;

    if (result && result?.error) {
      throw Error(result.error);
    }

    try {
      await Actions.customer.updateCustomer(session.tenant.id, {
        shipping: billingDetails.shipping,
        address: billingDetails.address,
        name: billingDetails.name,
      });
    } catch (e) {
      throw Error('Failed to update customer information. Please try again.');
    }
  };
};

export const defaults = (): BillingFormData => ({
  name: '',
  companyName: '',
  email: '',
  address: {
    address_line1: '',
    address_city: '',
    address_state: '',
    address_zip: '',
    address_country: 'US',
  },
  serviceAddress: {
    address_line1: '',
    address_city: '',
    address_state: '',
    address_zip: '',
    address_country: 'US',
  },
});

const getPostalCodeText = (country: string) => {
  switch (country) {
    case 'US':
      return 'Zip code';
    case 'GB':
      return 'Postcode';
    default:
      return 'Postal code';
  }
};

const InjectionErrorContext = createContext<string | null>(null);

interface Props {
  data: BillingFormData;
  onChange: (data: BillingFormData) => void;
  isServiceAddress: boolean;
  onIsServiceAddressChange: (newValue: boolean) => void;
  formTitle: string;
  stripeElementsRef?: (ref: any) => void;
  validation?: Validation<BillingFormData>;
  subHeaderComponent?: ReactNode;
}

// The country list is slow in dev environments, so memoizing the component to prevent
// excessive rerenders
const MemoizedFormSelect = memo(<T extends any = string>(props: FormSelectProps<T>) => (
  <FormSelect {...props} />
));

export const StripeBaseForm = ({
  data,
  onChange,
  stripeElementsRef = () => null,
  isServiceAddress = true,
  onIsServiceAddressChange,
  validation,
  formTitle,
  subHeaderComponent,
}: Props) => {
  const injectionError = useContext(InjectionErrorContext);
  const darkTheme = useDarkTheme();

  const handleServiceAddressChange = (serviceAddress: BillingAddress) => {
    onChange({
      ...data,
      serviceAddress: {
        ...data?.serviceAddress,
        ...serviceAddress,
      },
    });
  };

  const handleIsServiceAddressChange = ({ target: { checked } }) => {
    onIsServiceAddressChange(checked);
    onChange({ ...data, serviceAddress: defaults().serviceAddress });
  };

  const handleNameChange = ({ target: { value } }) => onChange({ ...data, name: value });

  const handleServiceNameChange = ({ target: { value } }) =>
    onChange({ ...data, companyName: value });

  const handleAddressChange = (address: BillingAddress) => {
    onChange({
      ...data,
      address: {
        ...data?.address,
        ...address,
      },
    });
  };

  const createOptions = useMemo(
    () => ({
      style: {
        base: {
          color: darkTheme ? '#F5F7FA' : '#151E29',
          lineHeight: '17px',
          fontFamily: "Helvetica, 'Helvetica Neue', Arial, sans-serif",
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: darkTheme ? '#FFB8C4' : '#CC254B',
          iconColor: darkTheme ? '#FFB8C4' : '#CC254B',
        },
      },
    }),
    [darkTheme]
  );

  if (injectionError) {
    return (
      <Alert
        type="danger"
        title="Load failed"
        description={injectionError}
        data-testid="stripe-injection-error"
      />
    );
  }

  return (
    <>
      <h4 className="tw-mb-6">{formTitle}</h4>
      {subHeaderComponent ?? <div>{subHeaderComponent}</div>}
      <div className="console-billing-section">
        <h6>Billing address</h6>
        <div className="tw-w-full">
          <FormInput
            required
            fluid
            label="Name"
            onChange={handleNameChange}
            value={data.name}
            autoComplete="name"
            data-testid="input-name"
            errorText={validation?.name?.message}
          />
        </div>
        <StripeAddressField data={data.address} onChange={handleAddressChange} />
      </div>
      <div className="console-billing-section">
        <h6 className="tw-mb-4">
          Service Address
          <Tip allowedPlacements={['right']}>
            <Tip.Trigger>
              <span className="tw-inline">
                <Icon
                  name="QuestionMarkCircleIconOutline"
                  type="solid"
                  className="tw-inline-block tw-h-5 tw-w-5 tw-ml-1 tw-mb-1"
                  aria-label="Service address information"
                />
              </span>
            </Tip.Trigger>
            <Tip.Content className="tw-max-w-xs" isPortaled={false}>
              This is the physical address of the company purchasing Neo4j Aura. It is used to
              calculate any applicable sales taxes.
            </Tip.Content>
          </Tip>
        </h6>
        <div className="tw-w-full">
          <Checkbox
            label="Same as billing address"
            checked={isServiceAddress}
            onChange={handleIsServiceAddressChange}
          />
        </div>
        {!isServiceAddress && (
          <StripeAddressField
            data={data.serviceAddress}
            onChange={handleServiceAddressChange}
            testIdPrefix="service-"
          />
        )}
        <div className="tw-w-full">
          <FormInput
            label="Company name"
            helpText="This company name will appear on your invoices and may be used for communication and customer support purposes."
            value={data.companyName}
            onChange={handleServiceNameChange}
            data-testid="input-company-name"
            errorText={validation?.companyName?.message}
            fluid
          />
        </div>
      </div>

      <div className="console-billing-section">
        <h6>Payment method</h6>
        <label className="tw-w-full">
          <span
            className="tw-text-sm tw-text-palette-neutral-text-weak tw-font-normal tw-inline-block tw-mb-2"
            style={{ letterSpacing: '0.25px', lineHeight: '20px' }}
          >
            Credit/Debit card details
          </span>
          <CardElement
            data-testid="stripe-credit-card"
            onReady={stripeElementsRef}
            options={{ hidePostalCode: true, iconStyle: 'default', ...createOptions }}
          />
        </label>
      </div>
    </>
  );
};

export const withStripeElements = component => {
  const WrappedComponent = component;

  return props => {
    if (globals.window.location.protocol !== 'https:') {
      const injectionError =
        'Unable to display payment form. You&apos;re running on an insecure connection';
      return (
        <InjectionErrorContext.Provider value={injectionError}>
          <WrappedComponent {...props} />
        </InjectionErrorContext.Provider>
      );
    }

    return (
      <Elements
        options={{
          fonts: [
            {
              cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:400,700',
            },
          ],
        }}
        stripe={stripePromise}
      >
        <WrappedComponent {...props} />
      </Elements>
    );
  };
};

interface StripeAddressFieldProps {
  data: BillingAddress;
  onChange: (data: BillingAddress) => void;
  disabled?: boolean;
  testIdPrefix?: string;
}

export const StripeAddressField = ({
  testIdPrefix = '',
  data,
  onChange,
  disabled = false,
}: StripeAddressFieldProps) => {
  const handleAddressLine1Change = ({ target: { value } }) =>
    onChange({ ...data, address_line1: value });
  const handleAddressCityChange = ({ target: { value } }) =>
    onChange({ ...data, address_city: value });
  const handleAddressStateChange = ({ target: { value } }) =>
    onChange({ ...data, address_state: value });
  const handleAddressZipChange = ({ target: { value } }) =>
    onChange({ ...data, address_zip: value });
  const handleAddressCountryChange = ({ value }) => onChange({ ...data, address_country: value });

  const selectedCountry = useMemo(
    () => countryList.find(c => [c.label, c.value].includes(data.address_country)),
    [data.address_country]
  );

  return (
    <>
      <div className="tw-w-full">
        <FormInput
          disabled={disabled}
          required
          fluid
          label="Street address"
          onChange={handleAddressLine1Change}
          value={data.address_line1 || ''}
          autoComplete="street-address"
          data-testid={testIdPrefix + 'input-address1'}
        />
      </div>
      <div className="tw-grow">
        <FormInput
          disabled={disabled}
          required
          fluid
          label="City"
          onChange={handleAddressCityChange}
          value={data.address_city || ''}
          autoComplete="address-level2"
          data-testid={testIdPrefix + 'input-address2'}
        />
      </div>
      {selectedCountry.value !== 'GB' ? (
        <div className="tw-grow">
          <FormInput
            disabled={disabled}
            fluid
            isOptional
            label="State"
            onChange={handleAddressStateChange}
            value={data.address_state || ''}
            autoComplete="address-level1"
            data-testid={testIdPrefix + 'input-state'}
          />
        </div>
      ) : null}
      <div className="tw-grow">
        <FormInput
          disabled={disabled}
          required
          fluid
          label={getPostalCodeText(selectedCountry.value)}
          onChange={handleAddressZipChange}
          value={data.address_zip || ''}
          autoComplete="postal-code"
          data-testid={testIdPrefix + 'input-postal-code'}
        />
      </div>
      <div className="tw-w-full">
        <MemoizedFormSelect
          disabled={disabled}
          placeholder="Country"
          label="Country"
          onChange={handleAddressCountryChange}
          value={selectedCountry.value}
          options={countryList}
          data-testid={testIdPrefix + 'select-country'}
        />
      </div>
    </>
  );
};

interface StripeFormProps {
  onSuccess: () => any;
  onCancel: () => any;
  cancelButtonText: string;
  submitButtonText: string;
  formTitle?: string;
  subHeaderComponent?: ReactNode;
  subFormComponent?: ReactNode;
}

const schema = yup.object({
  name: yup.string().max(140, 'The name can max be 140 characters'),
  companyName: yup
    .string()
    .min(3, 'The company name must be a minimum of 3 characters')
    .max(140, 'The company name can max be 140 characters')
    .required(),
});

export const validate = (data: BillingFormData) => {
  return validateYup(schema, data, false);
};

const handleErrorMessage = (error: ApiClientRequestError) => {
  switch (error.reason) {
    case 'card-funding-rejection':
      // There are currently two cases in the backend that throws this error so we just pass that error message through
      return error.responseMessage;
    default:
      return 'There was an issue with submiting the card details. Try again and if the issue persists contact customer support.';
  }
};

const StripeForm = ({
  cancelButtonText,
  submitButtonText,
  onSuccess,
  onCancel,
  formTitle,
  subHeaderComponent,
  subFormComponent,
}: StripeFormProps) => {
  const stripeSubmit = useStripeSubmit();
  const [data, setData] = useState(defaults());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isServiceAddress, setIsServiceAddress] = useState(true);
  const elementsRef = useRef(null);
  const [validation, setValidation] = useState(null);
  const session = useSession();

  const handleChange = (newData: BillingFormData) => {
    setValidation(null);
    setData(newData);
  };
  const handleSetIsServiceAddress = (newValue: boolean) => setIsServiceAddress(newValue);

  const handleErrorDismiss = () => setError(null);

  const handleSubmit = () => {
    const errors = validate(data);
    if (errors) {
      setValidation(errors);
      return false;
    }
    setLoading(true);
    stripeSubmit(data, isServiceAddress)
      .then(() => {
        setLoading(false);
        elementsRef.current.clear();
        onSuccess();
      })
      .catch(err => {
        setLoading(false);
        setError(handleErrorMessage(err));
      });
  };

  const submitButtonDisabled =
    loading ||
    !data.name ||
    !data.address.address_zip ||
    !data.address.address_city ||
    !data.address.address_line1 ||
    (!isServiceAddress &&
      (!data.serviceAddress.address_zip ||
        !data.serviceAddress.address_city ||
        !data.serviceAddress.address_line1));

  return (
    <Form onSubmit={handleSubmit} data-testid="stripe-form">
      <StripeBaseForm
        data={data}
        onChange={handleChange}
        isServiceAddress={isServiceAddress}
        onIsServiceAddressChange={handleSetIsServiceAddress}
        stripeElementsRef={ref => (elementsRef.current = ref)}
        validation={validation}
        formTitle={
          formTitle
            ? formTitle
            : session.tenant.hasBilling
            ? 'Replace payment method'
            : 'Add payment method'
        }
        subHeaderComponent={subHeaderComponent}
      />
      {subFormComponent && <div>{subFormComponent}</div>}
      <div className="tw-flex tw-justify-end tw-space-x-4">
        {onCancel ? (
          <Button type="button" onClick={onCancel} color="neutral" fill="outlined">
            {cancelButtonText}
          </Button>
        ) : null}
        <Button
          type="submit"
          loading={loading}
          data-testid="button-submit"
          disabled={submitButtonDisabled}
        >
          {submitButtonText}
        </Button>
      </div>
      {error && (
        <Alert
          type="danger"
          title="Form error"
          description={error}
          closeable
          onClose={handleErrorDismiss}
          className="tw-mt-6"
        />
      )}
    </Form>
  );
};

export default withStripeElements(StripeForm);
