import React, { useState } from 'react';
import LoadError from 'components/application/load-error';
import {
  Button,
  createColumnHelper,
  DataGrid,
  IconButton,
  Link,
  LoadingSpinner,
  useDefaultTable,
} from 'components/foundation';
import { Organization } from 'types/organization';
import { TenantSummary, tenantTypeDisplayName } from 'entities/tenant';
import { useOrganizationTenantsQuery } from 'remote/resources/tenants';
import { LinkTenantModal } from './link-tenant-modal';
import { TransferTenantModal } from './transfer-tenant-modal';

const tenantHeaderHelper = createColumnHelper<TenantSummary>();

const OrganizationTenantsGuard = (
  component: React.ComponentType<{
    organization: Organization;
    tenants: TenantSummary[];
    onRefreshTenants: () => void;
  }>
) => ({ organization }: { organization: Organization }) => {
  const tenantsQuery = useOrganizationTenantsQuery(organization.id); // eslint-disable-line

  const handleTenantsRefresh = () => tenantsQuery.refetch();

  if (tenantsQuery.isLoading) {
    return <LoadingSpinner size="large" expand minHeight={300} />;
  }

  if (tenantsQuery.isError) {
    return <LoadError />;
  }

  const Component = component;
  return (
    <Component
      organization={organization}
      tenants={tenantsQuery.data}
      onRefreshTenants={handleTenantsRefresh}
    />
  );
};

export const OrganizationTenantsBase = ({
  organization,
  tenants,
  onRefreshTenants,
}: {
  organization: Organization;
  tenants: TenantSummary[];
  onRefreshTenants: () => void;
}) => {
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  const handleLinkTenant = () => setLinkModalOpen(true);
  const handleLinkClose = () => setLinkModalOpen(false);
  const handleLinkSuccess = () => {
    onRefreshTenants();
    setLinkModalOpen(false);
  };

  const [transferTenant, setTransferTenant] = useState(null);

  const handleTransfer = (tenant: TenantSummary) => {
    setTransferTenant(tenant);
  };
  const handleTransferClose = () => {
    setTransferTenant(null);
  };
  const handleTransferSuccess = () => {
    onRefreshTenants();
    setTransferTenant(null);
  };

  const columns = [
    tenantHeaderHelper.accessor('id', {
      header: 'ID',
      minSize: 100,
      cell: c => {
        const tenant = c.row.original;

        return <Link href={`#admin/projects/${tenant.id}/users`}>{tenant.id}</Link>;
      },
    }),
    tenantHeaderHelper.accessor('name', {
      header: 'Name',
      minSize: 100,
    }),
    tenantHeaderHelper.accessor('planType', {
      header: 'Plan type',
      minSize: 100,
    }),
    tenantHeaderHelper.accessor('tenantType', {
      header: 'Tenant type',
      minSize: 100,
      cell: c => tenantTypeDisplayName(c.row.original.tenantType),
    }),
    tenantHeaderHelper.accessor('suspended', {
      header: 'Suspended?',
      minSize: 100,
    }),
    tenantHeaderHelper.accessor('options' as any, {
      header: null,
      minSize: 100,
      cell: c => {
        const tenant = c.row.original;

        return (
          <span className="tw-flex tw-flex-grow">
            <IconButton
              iconName="ArrowLeftIconOutline"
              data-testid={`tenant-transfer-${tenant.id}`}
              onClick={() => handleTransfer(tenant)}
              aria-label="Transfer Tenant"
              title="Transfer Tenant"
              clean
            />
          </span>
        );
      },
    }),
  ];

  const table = useDefaultTable({
    columns: columns,
    data: tenants,
  });

  return (
    <div>
      <div className="tw-flex tw-justify-between tw-mb-2">
        <h6>Tenants</h6>
        <div className="tw-justify-end tw-gap-2 tw-flex">
          <Button
            onClick={handleLinkTenant}
            iconName="PlusIconOutline"
            fill="outlined"
            data-testid="link-tenant-button"
          >
            Link
          </Button>
          <Button
            href={`#admin/organizations/${organization.id}/tenants/create`}
            iconName="PlusIconOutline"
            fill="outlined"
            data-testid="create-tenant-button"
          >
            Create
          </Button>
        </div>
      </div>
      <DataGrid tableInstance={table} />
      <LinkTenantModal
        organization={organization}
        open={linkModalOpen}
        onClose={handleLinkClose}
        onSuccess={handleLinkSuccess}
      />
      {!!transferTenant && (
        <TransferTenantModal
          tenant={transferTenant}
          open={!!transferTenant}
          onClose={handleTransferClose}
          onSuccess={handleTransferSuccess}
        />
      )}
    </div>
  );
};

export const OrganizationTenants = OrganizationTenantsGuard(OrganizationTenantsBase);
