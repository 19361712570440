import React from 'react';
import { useQueries } from 'react-query';
import GdsSessionResource from 'remote/resources/gds-sessions';
import NamespaceResource from 'remote/resources/namespaces';
import GdsSessionTable from './table';
import { Alert, IconButton, LoadingSpinner, NewTabLink, Page } from 'components/foundation';
import { useDatabaseQuery } from 'remote/resources/databases';
import { collectGdsSessions, createGdsTableRows } from './helpers';
import { useSession } from 'store';
import { DatabaseStatus } from 'entities/database';
import { useQuery } from 'react-query';

export enum IgnoreErrors {
  ALL = 'all',
  SERVER = 'server',
  NETWORK = 'network',
}

const DeleteIcon = ({ onClick }) => (
  <IconButton
    title="Delete Sessions"
    aria-label="Delete Session"
    iconName="TrashIconOutline"
    clean
    danger
    onClick={onClick}
  />
);

const GdsSessionsContent = () => {
  const { tenant } = useSession();

  const { data: databases, isLoading: dbsIsLoading, isError: dbsIsError } = useDatabaseQuery(
    tenant.id
  );

  const tenantMembersQuery = useQuery(
    ['tennants', tenant.id, 'members'],
    () => NamespaceResource.getMembers(tenant.id),
    {
      enabled: !!tenant.id,
    }
  );

  const databaseUsers = tenantMembersQuery.data ?? [];

  const gdsSessionsQuery = useQueries(
    databases
      ?.filter(db => db.DatabaseStatus === DatabaseStatus.RUNNING)
      .map((db, idx) => ({
        queryKey: ['gds-sessions', idx],
        queryFn: () => GdsSessionResource.list(db.DbId),
      })) || []
  );

  const isAnySessionLoading = gdsSessionsQuery.some(query => query.isLoading);
  const isAnySessionsError = gdsSessionsQuery.some(query => query.isError);

  if (dbsIsLoading || isAnySessionLoading || tenantMembersQuery.isLoading) {
    return <LoadingSpinner size="large" expand />;
  }

  if (dbsIsError || isAnySessionsError || tenantMembersQuery.isError) {
    return (
      <Alert
        description="Something went wrong. Please try again later"
        type="danger"
        className="tw-mt-2"
        data-testid="error-alert"
      />
    );
  }

  const onDeleteSession = async (sessionId, dbId) => {
    await GdsSessionResource.destroy(dbId, sessionId);
    gdsSessionsQuery.forEach(query => query.refetch());
  };

  const gdsSessions = collectGdsSessions(gdsSessionsQuery);
  const dataRows = createGdsTableRows(gdsSessions, databases, databaseUsers);

  const rows = dataRows.map(row => ({
    ...row,
    delete: <DeleteIcon onClick={() => onDeleteSession(row.sessionId, row.instanceId)} />,
  }));

  return <GdsSessionTable rows={rows} />;
};

const GdsSessionsPage = () => (
  <Page plain fullWidth>
    <div className="tw-flex tw-items-center tw-gap-2">
      <h2 className="">Sessions</h2>
      <NewTabLink href="https://neo4j.com/docs/graph-data-science-client/current/gds-session/">
        Documentation
      </NewTabLink>
    </div>
    {<GdsSessionsContent />}
  </Page>
);

export default GdsSessionsPage;
