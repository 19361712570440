import React, { useCallback, useState } from 'react';
import track, { useTracking } from 'react-tracking';
import { formatBytes } from 'components/utils';
import { Divider, Dialog, Alert, Dropzone, ProgressBar, TextLink } from 'foundation';

import Button from 'foundation/button';
import { Database, Tier } from 'entities/database';
import { Product } from 'types/product';
import { usePermissions, useSession } from 'store';
import { Status } from './upload-handlers';
import './dropzone.css';
import { Action } from 'types/user';

const Confirmation = ({ onCancel, onConfirm, file, open }) =>
  file && file.name && file.size ? (
    <Dialog open={open} onClose={onCancel}>
      <Dialog.Header>Are you sure?</Dialog.Header>
      <Dialog.Content>
        All existing data in your database will be replaced with {file.name} (
        {formatBytes(file.size)})
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={onCancel} color="neutral" fill="outlined">
          Cancel
        </Button>
        <Button onClick={onConfirm} data-testid="upload-import-confirm-button">
          Upload
        </Button>
      </Dialog.Actions>
    </Dialog>
  ) : null;

interface ModalState {
  visible: boolean;
  file?: File;
  error?: string;
}

interface Props {
  onSubmit?: (file: File) => any;
  uploading?: boolean;
  processing?: boolean;
  progress?: number;
  status?: Status;
  fileMetadata?: { name: string; size: number };
  database: Database;
  isTesting: boolean;
  message?: string;
  errorCode?: string;
}

const acceptedFileExtensions: Record<Product, string[]> = {
  [Product.AURA_DB]: ['.dump', '.backup'],
  [Product.AURA_DS]: ['.dump', '.tar'],
};

const fileIsValid = ({ product, file }: { product: Product; file?: File }) => {
  const acceptedExtensions = acceptedFileExtensions[product];
  return file && acceptedExtensions.some(extension => file.name.endsWith(extension));
};

const ImportDropzone = ({
  onSubmit,
  uploading,
  processing,
  progress,
  status,
  fileMetadata,
  database,
  isTesting,
  message,
  errorCode,
}: Props) => {
  const session = useSession();
  const { allow } = usePermissions();
  const allowImport = allow(
    Action.OVERWRITE,
    `namespaces/${session.currentTenant}/databases/${database.DbId}`
  );
  const tracking = useTracking();
  const importEnabled = database.AvailableActions.import.enabled && allowImport;
  const [modalState, setModalState] = useState<ModalState>({
    visible: false,
  });
  const { name } = fileMetadata ?? {};

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];

    if (!fileIsValid({ product: session.product, file })) {
      setModalState({ visible: false, file, error: 'Invalid file extension' });
      return false;
    }
    setModalState({ visible: true, file });
  }, []);

  const handleSelectDumpClick = () => {
    tracking.trackEvent({
      action: 'dump_file_select',
      properties: { event_label: 'clicked' },
    });
  };

  const infoMessage = (
    <>
      <p>
        Data upload supports databases running version 4.4 and up. Previous versions are not
        supported and should be upgraded prior to import. Upgrade implementation steps can be found{' '}
        <TextLink
          href="https://neo4j.com/docs/upgrade-migration-guide/current/version-4/upgrade/upgrade-4.4/standalone/#prepare-upgrade-single-4.4"
          externalLink
        >
          here
        </TextLink>
        .
      </p>
      <p>
        Don&apos;t know how to create a dump file? Follow{' '}
        <TextLink
          href="https://neo4j.com/docs/operations-manual/current/backup-restore/offline-backup/#offline-backup"
          externalLink
        >
          these instructions
        </TextLink>
        .
      </p>
    </>
  );

  let errorMessage: string | null = null;

  if (status === Status.ERROR) {
    switch (errorCode) {
      // A code used in our own frontend code
      case 'xhr-error':
        errorMessage = 'A network problem interrupted or prevented the file upload. ';
        errorMessage += 'Check your connection and try again.';
        break;

      // A code used in our own frontend code
      case 'xhr-timeout':
        errorMessage = 'The upload failed after taking too long. ';
        errorMessage +=
          'Ensure you have sufficient bandwidth to upload the dump file and try again.';
        break;

      case 'ImportFileTypeInvalid':
        errorMessage = 'Invalid dump file extension. Only .dump or .tar files are accepted.';
        break;

      /* Max size exceeded error codes */
      case 'EntityTooLarge': // AWS error code
      // fall through
      case 'uploadTooLarge': // GCP error code
      // fall through
      case 'MaxBlobSizeConditionNotMet': // Azure error code
        errorMessage = 'The dump file provided exceeded the maximum upload size.';
        break;

      // Our own Console API error code.
      // Message can be used directly for this one, and is preferred because
      // it contains information about the minimum size required to upload
      // the dump file in question.
      case 'ImportExceedsMaxSize':
        errorMessage = message;
        break;

      /* Generic error codes */

      // Generic AWS error codes
      case 'InternalError':
      case 'RequestTimeout':
      case 'RequestTimeTooSkewed':
      case 'SlowDown':
      case '503 SlowDown':
      // Generic GCP error codes
      // fall through
      case 'uploadBrokenConnection':
      case 'rateLimitExceeded':
      case 'backendError':
      case 'internalError':
        errorMessage = 'The upload failed due to an error on the server. ';
        errorMessage += 'Please try again later.';
        break;

      /* Fallback error message */
      default:
        errorMessage = 'Dump upload failed.';
        break;
    }
  }

  const alertMessage =
    errorMessage !== null ? (
      <p className="tw-text-palette-neutral-text-weak">
        {errorMessage} Contact{' '}
        <TextLink href="https://support.neo4j.com/s/" externalLink>
          Aura Support
        </TextLink>{' '}
        if you need assistance.
        {errorCode
          ? ` Please include this technical fault code: '${errorCode}' ` +
            'when you raise a support ticket.'
          : null}
      </p>
    ) : null;

  const product =
    session.product === Product.AURA_DB
      ? 'https://neo4j.com/docs/aura/auradb/importing/import-database/#_neo4j_admin_database_upload'
      : 'https://neo4j.com/docs/aura/aurads/importing-data/import-db/#_neo4j_admin_database_upload';

  return (
    <>
      <div data-appcues-id="dropzone" data-testid="dropzone">
        <Dropzone
          className="drag-and-drop"
          data-testid="dropzone-input"
          dropZoneOptions={{
            onDrop,
            multiple: false,
            onFileDialogOpen: handleSelectDumpClick,
            disabled: !importEnabled,
          }}
          supportedFilesDescription={
            <>
              <p data-testid="dropzone-import-copy">
                Want to upload a dump larger than 4GB?{' '}
                <TextLink
                  href={product}
                  externalLink
                  onClick={() => {
                    tracking.trackEvent({
                      action: 'import_link',
                      properties: { event_label: 'clicked' },
                    });
                  }}
                >
                  Click here
                </TextLink>{' '}
                to view the Neo4j Admin &apos;upload&apos; command in the Aura Docs.
              </p>
              {database.Tier === Tier.FREE &&
                database.Limits &&
                database.Limits.MaxNodes &&
                database.Limits.MaxRelationships && (
                  <p data-testid="dropzone-import-free-copy">
                    The graph you upload should be smaller than{' '}
                    {database.Limits.MaxNodes.toLocaleString()} nodes and{' '}
                    {database.Limits.MaxRelationships.toLocaleString()} relationships.
                  </p>
                )}
            </>
          }
          loadingComponent={
            (uploading || processing) && (
              <>
                <ProgressBar
                  data-testid="uploading-progress"
                  className="tw-w-48 tw-m-auto"
                  value={progress}
                  size="large"
                  heading="Uploading"
                />
                <div className="tw-font-light tw-my-4">{name}</div>
                <div className="tw-text-palette-danger-text tw-font-bold">
                  Please stay on this tab for the duration of the import!
                </div>
              </>
            )
          }
          isTesting={isTesting}
          customTitle={
            <>
              <span className="tw-font-light">
                Drag & Drop your Neo4j <span className="tw-font-semibold">.dump</span>{' '}
                {session.product === Product.AURA_DS && (
                  <>or {<span className="tw-font-semibold">.tar </span>}</>
                )}
                file here (4GB or less)
              </span>
            </>
          }
          rejectedMessage="Invalid file extension! The file you tried to upload was in the wrong format."
          acceptedFileExtensions={acceptedFileExtensions[session.product]}
        />
      </div>
      <Divider hidden />
      {alertMessage && (
        <>
          <Alert
            data-testid="dropzone-alert"
            type={'danger'}
            description={alertMessage}
            icon
            name={'Upload error'}
          />
          <Divider hidden />
        </>
      )}

      {infoMessage && (
        <Alert
          data-testid="dropzone-info"
          type={'info'}
          description={infoMessage}
          icon
          name={'Information for generating .dump files'}
        />
      )}
      <Confirmation
        file={modalState.file}
        open={modalState.visible}
        onConfirm={() => {
          onSubmit(modalState.file);
          setModalState({ visible: false });
        }}
        onCancel={() => setModalState({ visible: false })}
      />
    </>
  );
};

export default track()(ImportDropzone);
