import React, { useState } from 'react';
import { Link, Label, IconButton } from 'foundation';
import HelpMenuDropdown from './help-menu';
import Profile from './profile';
import Neo4jLogoLightTheme from 'ui/icons/aura_logo_light_theme.svg';
import Neo4jLogoDarkTheme from 'ui/icons/aura_logo_dark_theme.svg';
import {
  BillingMethod,
  PlanType,
  TenantType,
  tierDisplayName,
  tierDisplayNameInitials,
} from 'entities/tenant';
import useCanny from 'remote/canny';
import track, { useTracking } from 'react-tracking';
import { SessionState } from 'state/session-store';
import BillingUsageSummary from 'application/usage/summary';
import { TenantSwitcher } from './tenant-switcher';
import { SessionStore } from 'state';
import Actions from 'actions';
import logger from 'logger';
import { useMediaQuery } from 'utils/hooks';
import { breakpoints } from 'utils/breakpoints';
import { getHomePath } from 'components/utils';
import { usePermissions } from 'store';
import { Action } from 'types/user';
import { invitesToShow } from '../invite-consent';
import { useInOrgSettingsPage } from 'components/pages/organization-settings/helpers';
import { Tier } from 'entities/database';

const PREPAID_TENANT_TITLE = 'This tenant is prepaid';

interface TopNavigationProps {
  session: SessionState;
  isDarkTheme?: boolean;
}

const TopNavigation = ({ session, isDarkTheme }: TopNavigationProps) => {
  const tracking = useTracking();
  useCanny(session);
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);
  const handleHelpClick = event => setHelpAnchorEl(event.currentTarget);
  const handleHelpClose = () => setHelpAnchorEl(null);
  const aboveMobileWidth = useMediaQuery(breakpoints.lg);
  const inOrgSettingsPage = useInOrgSettingsPage();

  const handleShowInvites = () => {
    SessionStore.setHideInviteConsentForm(false);
    Actions.invites.refreshUserInvites(session.userId).catch(err => logger.error(err));
  };

  const isTenantSwitcherEnabled = () => {
    if (location.hash.startsWith('#settings') || inOrgSettingsPage) return false;

    return true;
  };

  const homePath = getHomePath(session);

  const { tenantType, hasBilling, billingMethod } = session.tenant || {};
  const displayInvites = invitesToShow(session).length > 0;
  const { allow } = usePermissions();

  const hasPrepaidBilling =
    billingMethod === BillingMethod.PREPAID && tenantType === TenantType.PERSONAL;
  const hasUsagePermission =
    tenantType === TenantType.PERSONAL &&
    hasBilling &&
    allow(Action.READ, `tenants/${session.currentTenant}/usage`);
  const showBillingStatus = hasUsagePermission || hasPrepaidBilling;
  return (
    <>
      <div
        className="top-nav tw-bg-palette-neutral-bg-weak tw-border-palette-neutral-border-weak tw-border-b"
        data-testid="top-navigation"
      >
        <div className="left-items">
          <Link data-testid="logo" href={homePath} className="tw-flex tw-no-underline">
            {isDarkTheme ? (
              <Neo4jLogoDarkTheme className="logo" />
            ) : (
              <Neo4jLogoLightTheme className="logo" />
            )}
            {session.tenant?.planType === PlanType.ENTERPRISE && (
              <Label
                data-testid="enterprise-label"
                className="console-label tw-self-center tw-ml-2"
              >
                {aboveMobileWidth ? (
                  tierDisplayName(session.tenant, Tier.ENTERPRISE)
                ) : (
                  <span title={tierDisplayName(session.tenant, Tier.ENTERPRISE)}>
                    {tierDisplayNameInitials(session.tenant, Tier.ENTERPRISE)}
                  </span>
                )}
              </Label>
            )}
          </Link>
          {isTenantSwitcherEnabled() ? (
            <TenantSwitcher />
          ) : (
            <div data-testid="tenant-switcher-disabled" className="tw-hidden" />
          )}
        </div>

        <div className="right-items">
          {displayInvites && (
            <IconButton
              clean
              onClick={() => handleShowInvites()}
              title="Pending invites"
              className="tw-text-palette-neutral-text-default tw-center tw-relative"
              iconName="EnvelopeIconOutline"
              aria-label="Pending invites"
              statusType="info"
            />
          )}
          <IconButton
            clean
            onClick={handleHelpClick}
            data-testid="top-nav-help"
            title="Help"
            iconName="QuestionMarkCircleIconOutline"
            className="tw-text-palette-neutral-text-default tw-center"
            aria-label="Help actions"
          />
          <IconButton
            clean
            href="#settings"
            iconName="Cog6ToothIconOutline"
            className="tw-text-palette-neutral-text-default tw-center"
            data-testid="top-nav-settings"
            title="Settings"
            aria-label="Settings"
          />
          <IconButton
            title="Changelog"
            clean
            data-canny-changelog
            data-testid="change-log"
            onClick={() => {
              tracking.trackEvent({
                action: 'change_log_link',
                properties: { event_label: 'clicked' },
              });
            }}
            iconName="MegaphoneIconOutline"
            className="tw-text-palette-neutral-text-default tw-center"
            style={{ display: 'flex' }}
            aria-label="Changelog"
          />
          <div className="vertical-divider" />
          {showBillingStatus && (
            <>
              {hasPrepaidBilling ? (
                <div className="n-subheading-small tw-text-palette-neutral-text-weaker">
                  {PREPAID_TENANT_TITLE}
                </div>
              ) : (
                <Link
                  href="/?tab=usage#settings/billing"
                  className="usage-item tw-no-underline tw-text-palette-neutral-text-weak"
                >
                  <BillingUsageSummary session={session} />
                </Link>
              )}
              <div className="vertical-divider" />
            </>
          )}
          <Profile session={session} className="profile-item" />
        </div>
      </div>
      <HelpMenuDropdown
        session={session}
        open={!!helpAnchorEl}
        anchorEl={helpAnchorEl}
        onClose={handleHelpClose}
      />
    </>
  );
};

export default track()(TopNavigation);
