import React, { useMemo, useState } from 'react';
import Button from 'foundation/button';
import { Database } from 'entities/database';
import { EditSecondariesCountDatabaseFormData } from 'application/edit-secondaries/form-data';
import Actions from 'actions';
import { Dialog } from 'foundation/dialog';
import { Form } from 'foundation/form-element';
import { EditSecondariesCountDatabaseForm } from './edit-secondaries-form';
import logger from 'logger';
import { useNotify } from 'state/notifications';

type Props = {
  open: boolean;
  onClose: () => void;
  database: Database;
};

const ChangeSecondariesDatabaseModal = ({ open, onClose, database }: Props) => {
  const notify = useNotify();
  const initialCount = database.DesiredSettings.SecondariesCount;
  const [data, setData] = useState<EditSecondariesCountDatabaseFormData>(() => ({
    secondariesCount: initialCount,
  }));

  const [updateRequestInProgress, setUpdateRequestInProgress] = useState(false);

  const handleChange = (newData: EditSecondariesCountDatabaseFormData) => {
    setData({
      ...newData,
    });
  };

  const updateDatabase = async () => {
    setUpdateRequestInProgress(true);

    try {
      await Actions.databases.updateDatabase(database.DbId, {
        SecondariesCount: data.secondariesCount,
      });
      setUpdateRequestInProgress(false);
      onClose();
    } catch (e) {
      setUpdateRequestInProgress(false);
      logger.error(e);
      notify.error('Failed to change secondaries count');
    }
  };

  const hasChangedValue = useMemo(() => data.secondariesCount !== initialCount, [data]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      modalProps={{
        'data-testid': 'change-secondaries-database-modal',
      }}
    >
      <Dialog.Header>Edit secondaries count</Dialog.Header>
      <Dialog.Content>
        <Form onSubmit={updateDatabase} data-testid="change-secondaries-db-form">
          <EditSecondariesCountDatabaseForm
            data={data}
            onChange={handleChange}
            initialCount={initialCount}
          />
        </Form>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={onClose}
          data-testid="clone-cancel-button"
          fill="outlined"
          color="neutral"
          disabled={updateRequestInProgress}
        >
          Cancel
        </Button>
        <Button
          disabled={!hasChangedValue}
          onClick={updateDatabase}
          loading={updateRequestInProgress}
          data-testid="update-secondaries-count"
        >
          Update
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default ChangeSecondariesDatabaseModal;
