import Actions from 'actions';
import { Alert, Button, Form, FormInput, LoadingSpinner } from 'components/foundation';
import Neo4jLogo from 'ui/icons/aura-colored.svg';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'store';
import { parse } from 'query-string';

export const OrganizationLookupPage = () => {
  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  const queryParams = parse(search);

  const error = queryParams.error_description as string;
  const querySsoId = queryParams.org_id;

  let initialSsoId = '';
  if (querySsoId && (typeof querySsoId === 'string' || querySsoId instanceof String)) {
    initialSsoId = querySsoId as string;
  }

  const [ssoId, setSsoId] = useState(initialSsoId);
  const handleSsoIdChange = e => {
    setSsoId(e.target.value);
  };

  const handleSubmit = () => {
    Actions.auth.login({
      organization: ssoId,
      redirect_uri: location.origin,
    });
  };

  useEffect(() => {
    if (!error && initialSsoId && queryParams.autosubmit === 'true') {
      handleSubmit();
    } else {
      // Hide loading when not autosubmitting
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <LoadingSpinner expand size="large" />;
  }

  return (
    <div className="tw-flex tw-justify-center">
      <div className="tw-bg-palette-neutral-bg-weak tw-rounded-md tw-mt-12 tw-p-8 tw-flex tw-flex-col tw-gap-4 tw-max-w-[500px] tw-flex-1 tw-drop-shadow-md">
        <Neo4jLogo />
        <h5>Login with organization SSO</h5>
        <Form onSubmit={handleSubmit}>
          <FormInput
            value={ssoId}
            onChange={handleSsoIdChange}
            placeholder="Enter Organization SSO ID"
            label="Organization SSO ID"
            fluid
            data-testid="organization-id-input"
          />
        </Form>
        {error && <Alert type="danger" title="An error occurred" description={error} />}
        <div className="tw-flex tw-justify-end tw-gap-2">
          <Button as="a" href="/" fill="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} data-testid="organization-submit-button" disabled={!ssoId}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};
