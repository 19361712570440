import React, { useState } from 'react';
import { Dialog, Button, Alert, Form } from 'foundation';
import { Validation } from 'utils/validation';
import NamespacesResource from 'remote/resources/namespaces';
import SsoConfigResource from 'remote/resources/sso-configs';
import { validateSsoConfig } from './sso-config-validation';
import SsoConfigFormFields from './sso-config-form-fields';
import { LinkedTenant, SsoConfigFormAction, SsoConfigFormData } from '../types';
import { IdpType } from 'types/sso-config';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';

export interface CreateSsoConfigModalProps {
  open: boolean;
  closeModal(): void;
  saveAndClose(): void;
  organizationId: string;
}

const defaultValues = (organizationId): SsoConfigFormData => ({
  displayName: '',
  idpType: IdpType.OKTA,
  discoveryURI: '',
  issuer: '',
  authorizationEndpoint: '',
  tokenEndpoint: '',
  jwksURI: '',
  clientID: '',
  roleMapping: '',
  needsSecret: true,
  clientSecret: '',
  organizationId: organizationId,
  organizationLoginMethod: false,
  linkedTenants: [],
  tenantLoginMethod: false,
});

const CreateSsoConfigModal = ({
  open,
  closeModal,
  saveAndClose,
  organizationId,
}: CreateSsoConfigModalProps) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [ssoConfig, setSsoConfig] = useState<SsoConfigFormData>(defaultValues(organizationId));
  const [validation, setValidation] = useState<Validation<any>>(null);
  const [hasFailedValidation, setHasFailedValidation] = useState(false);

  const handleClose = () => {
    setSsoConfig(defaultValues(organizationId));
    setErrorMessage(null);
    setValidation(null);
    setHasFailedValidation(false);
    closeModal();
  };

  const updateSsoConfigIdWithHandling = async (tenantId, ssoConfigId) => {
    try {
      await NamespacesResource.linkSsoConfigId(tenantId, ssoConfigId);
      return { tenantId, ssoConfigId, success: true };
    } catch (error) {
      return { tenantId, ssoConfigId, success: false, error: error.message };
    }
  };

  const handleUpdateTenantsWithSso = async (ssoConfigId: string, linkedTenants: LinkedTenant[]) => {
    const updatePromises = linkedTenants.map(({ tenantId }) =>
      updateSsoConfigIdWithHandling(tenantId, ssoConfigId)
    );
    try {
      const results = await Promise.all(updatePromises);
      const failures = results.filter(result => !result.success);
      if (failures.length > 0) {
        setErrorMessage(
          `Failed to update SSO Config ID for tenants: ${failures
            .map(failure => failure.tenantId)
            .join(', ')}`
        );
      }
    } catch (error) {
      setErrorMessage('Unknown failure when updating SSO Config ID for tenants');
    }
  };

  const handleSubmit = async () => {
    setErrorMessage(null);
    const newValidation = validateSsoConfig(ssoConfig);
    if (newValidation) {
      setValidation(newValidation);
      setHasFailedValidation(true);
      return;
    }

    setLoading(true);
    try {
      const { linkedTenants, ...dataToSubmit } = ssoConfig;
      const createdSsoConfig = await SsoConfigResource.createSsoConfig(
        organizationId,
        dataToSubmit
      );
      await handleUpdateTenantsWithSso(createdSsoConfig.ssoConfigId, linkedTenants);
      setLoading(false);
      saveAndClose();
    } catch (err) {
      if (
        err instanceof ApiClientRequestError &&
        err.response.status === 422 &&
        err.fieldErrors &&
        'DiscoveryUri' in err.fieldErrors
      ) {
        setValidation({ discoveryURI: { message: err.fieldErrors.DiscoveryUri[0], error: null } });
        setErrorMessage(err.responseMessage || 'Unknown error.');
      } else if (err instanceof ApiClientRequestError && err.reason === 'duplicate-sso-config-id') {
        setValidation({ ssoConfigId: { message: 'This ID is already taken', error: null } });
        setErrorMessage(err.responseMessage || 'Unknown error.');
      } else if (err instanceof ApiClientRequestError) {
        setErrorMessage(err.responseMessage || 'Unknown error.');
      } else {
        setErrorMessage(err.toString());
      }
      setLoading(false);
    }
  };

  const maybeValidate = (data: SsoConfigFormData) => {
    if (hasFailedValidation) {
      const validationMsgs = validateSsoConfig(data);
      setValidation(validationMsgs);
    }
  };

  const handleChange = (data: SsoConfigFormData) => {
    setSsoConfig(data);
    maybeValidate(data);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      modalProps={{ 'data-testid': 'create-sso-config-modal' }}
    >
      <Dialog.Header>New SSO Configuration</Dialog.Header>
      <Dialog.Content data-testid="create-sso-config-content">
        <Alert
          type="info"
          description="Note: To create a SSO Config, either a Discovery URI or a combination of Issuer,
Authorization Endpoint, Token Endpoint and JWKS URI is required."
        />
        <Form id="create-sso-config-form" onSubmit={handleSubmit}>
          <SsoConfigFormFields
            config={ssoConfig}
            onChange={handleChange}
            validation={validation}
            formAction={SsoConfigFormAction.CREATE}
            organizationId={ssoConfig.organizationId}
          />
        </Form>

        {errorMessage && (
          <Alert
            className="tw-mt-2"
            type="danger"
            data-testid="create-sso-config-error-message"
            description={`There was an error creating the SSO Config: ${errorMessage}`}
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          color="neutral"
          fill="outlined"
          disabled={loading}
          onClick={handleClose}
          data-testid="discard-create-sso-config-button"
        >
          Discard
        </Button>
        <Button
          loading={loading}
          type="submit"
          form="create-sso-config-form"
          data-testid="create-sso-config-button"
        >
          Create
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default CreateSsoConfigModal;
