import { parseISO } from 'date-fns';

import ConfirmModal, { ConfirmAction } from 'foundation/confirm-modal';
import DatabaseName from 'application/db-name';
import React from 'react';
import { safeFormat } from 'utils/date-time';
import { CDCRestoreSnapshotWarning } from 'application/edit-cdc-enrichment-mode/cdc-warnings';

const RestoreSnapshotModal = ({
  DbName,
  DbId,
  Timestamp,
  hasCDCEnrichmentMode = false,
  loading,
  open,
  onConfirm,
  onCancel,
}) => (
  <ConfirmModal
    title="Restore from Snapshot"
    content={
      <div>
        <p>
          This will replace all data in{' '}
          <strong>
            <DatabaseName Name={DbName} DbId={DbId} />
          </strong>{' '}
          with the backup taken {safeFormat(parseISO(Timestamp), 'do MMM, H:mm a')}.
        </p>
        {hasCDCEnrichmentMode && <CDCRestoreSnapshotWarning />}
      </div>
    }
    primary
    action={ConfirmAction.RESTORE}
    buttonTextLoading="Restoring"
    confirmText="RESTORE"
    loading={loading}
    open={open}
    onConfirm={onConfirm}
    onCancel={onCancel}
    negative={false}
  />
);

export default RestoreSnapshotModal;
