export enum ErrorReasons {
  ACCOUNT_TERMINATED = 'user-account-terminated',
  CARD_FUNDING_REJECTION = 'card-funding-rejection',
  DATABASE_BEING_CLONED = 'db-is-being-cloned',
  EMAIL_IN_USE = 'email-in-use',
  EMAIL_UNVERIFIED = 'email-unverified',
  INVALID_TOKEN = 'token-invalid',
  ONGOING_DATABASE_OPERATION = 'ongoing-database-operation',
  OPERATIONS_LIMIT_REACHED = 'operations-limit-reached',
  QUOTA_EXCEEDED = 'quota-exceeded',
  REGION_AT_CAPACITY = 'region-at-capacity',
  UNAUTHORIZED = 'unauthorized',
  ACCOUNT_LINK_REQUIRED = 'account-link-required',
}
