import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Database,
  DatabaseStatus,
  friendlyCloudProviderName,
  friendlyRegionName,
  getConnectionUri,
  Tier,
} from 'entities/database';

import SnapshotSection from 'components/application/snapshot-table';
import { Page } from 'foundation/page';
import DatabaseMetricChartsSection from 'application/metric-charts';
import LogsSection from 'application/logs';
import Import from 'pages/import';

import track from 'react-tracking';
import { Tabs } from 'foundation/tabs';
import './databases.css';
import Statuses from 'application/db-status';
import { DatabaseActions } from 'application/db-actions';
import { getNeo4jVersionText, needsMigration } from 'utils/neo4j-versions';

import { asPercentageString } from 'components/utils';
import transitionValue from 'application/db-card/transition-value';
import { SessionState } from 'state/session-store';
import Icon from 'ui/icons';
import {
  OpenWithBloom,
  OpenWithBrowser,
  OpenWithImporter,
  OpenWithWorkspace,
} from 'application/open-with';
import { Button } from 'foundation/button';
import {
  selectDatabaseFactory,
  selectDatabaseSnapshotsFactory,
  usePermissions,
  useSelector,
  useSession,
} from 'store';
import {
  useDatabasePoll,
  useDatabaseRequest,
  useSnapshotsPoll,
  useSnapshotsRequest,
} from 'components/helpers/databases';
import LoadingSpinner from 'foundation/loading-spinner';
import LoadError from 'application/load-error';
import { IgnoreErrors } from 'remote/api-client';
import { availableRegionsForTier, getProductFromTier } from 'entities/tenant';
import { Alert, CopyTextToClipBoardButton, Link, Tip } from 'foundation';
import Actions from 'actions';
import { useDarkTheme } from 'utils/hooks';
import { getEncryptionKeyId } from 'entities/encryption-keys';
import cx from 'classnames';
import { Snapshot } from 'types/snapshot';
import { Action } from 'types/user';
import { PermissionTip } from 'application/permission-tip';
import { isCDCEnrichmentModeEnabled } from 'entities/cdc-enrichment-mode';
import {
  handleMigrationAssistantClick,
  isMigrationAssistantAccessible,
} from 'application/open-with/open-with-migration-assistant';

const getMemoryText = (db: Database) =>
  transitionValue(db.AppliedSettings.Memory, db.DesiredSettings.Memory, db.DatabaseStatus);
const getCpuText = (db: Database) =>
  transitionValue(db.AppliedSettings.Cpu, db.DesiredSettings.Cpu, db.DatabaseStatus);
const getStorageText = (db: Database) =>
  transitionValue(db.AppliedSettings.Storage, db.DesiredSettings.Storage, db.DatabaseStatus);
const getSecondariesCountText = (db: Database) =>
  transitionValue(
    db.AppliedSettings.SecondariesCount !== undefined ? db.AppliedSettings.SecondariesCount : '-',
    db.DesiredSettings.SecondariesCount,
    db.DatabaseStatus
  );
const getCDCEnrichmentModeText = (db: Database) =>
  transitionValue(
    db.AppliedSettings.CDCEnrichmentMode !== undefined
      ? db.AppliedSettings.CDCEnrichmentMode
      : 'OFF',
    db.DesiredSettings.CDCEnrichmentMode,
    db.DatabaseStatus
  );

const DbInfo = ({ title, value, ...props }) => (
  <div {...props} className="db-info">
    <span className="info-title">{title}</span>
    <strong className="info-value">{value || '-'}</strong>
  </div>
);

const DetailsHeader = ({ database, session }: { database: Database; session: SessionState }) => {
  const { CurrentNodes, CurrentRelationships } = database.Counts || {};
  const { MaxNodes, MaxRelationships } = database.Limits || {};
  const connectionUri = getConnectionUri(database);
  const privateBoltUrl = database.PrivateBoltUrl;
  const privateConnectionEnabled =
    [Tier.ENTERPRISE, Tier.AURA_DSE].includes(database.Tier) && privateBoltUrl;
  const product = getProductFromTier(database.Tier);
  const isDarkTheme = useDarkTheme();
  const regionIconName =
    isDarkTheme && database.CloudProvider === 'aws' ? 'aws-dark' : database.CloudProvider;
  const showCmekEnabled =
    [Tier.ENTERPRISE, Tier.AURA_DSE].includes(database.Tier) && session.tenant.capabilities.cmek;
  const showSecondaries =
    session.tenant.capabilities?.secondaries &&
    database.DesiredSettings.Version === '5' &&
    database.Tier === Tier.ENTERPRISE;

  const shouldDisplayCDCEnrichmentMode = isCDCEnrichmentModeEnabled(session, database);

  const classes = cx('tw-truncate', {
    'console-party': database.Name.toLowerCase().endsWith('(party-mode)'),
  });

  return (
    <div className="db-details-header tw-flex tw-flex-col tw-mb-6">
      <div className="tw-flex">
        <Link href={`/?product=${product}#databases`} className="back-to-databases tw-mb-2">
          <strong className="tw-text-xs tw-flex">
            <Icon name="ChevronLeftIconOutline" className="tw-h-3 tw-w-3 tw-mr-1 tw-self-center" />
            Back to Instances
          </strong>
        </Link>
      </div>
      <div className="db-details-header-top-row tw-flex tw-justify-between">
        <div className="tw-flex tw-truncate">
          <h3 className={classes} title={database.Name}>
            {database.Name}
            {privateConnectionEnabled && (
              <Tip allowedPlacements={['top', 'bottom']}>
                <Tip.Trigger>
                  <div className="tw-ml-2 tw-inline-flex">
                    {database.PublicAccessEnabled ? (
                      <Icon
                        name="ShieldExclamationIconOutline"
                        ariaLabel="Private Connection with public access enabled"
                        size="large"
                      />
                    ) : (
                      <Icon
                        name="ShieldCheckIconOutline"
                        ariaLabel="Private Connection"
                        size="large"
                      />
                    )}
                  </div>
                </Tip.Trigger>
                <Tip.Content className="tw-z-10">
                  {database.PublicAccessEnabled
                    ? 'Private Connection with public access enabled'
                    : 'Private Connection'}
                </Tip.Content>
              </Tip>
            )}
          </h3>
          <Statuses database={database} size="small" className="tw-mx-4 tw-font-bold" />
        </div>
        <div className="tw-flex tw-gap-2">
          <DatabaseActions database={database} size="medium" />
          {session.workspaceEnabled ? (
            <OpenWithWorkspace database={database}>
              {({ href: workspaceHref, disabled }) => (
                <Button
                  fill="outlined"
                  className="workspace-button"
                  disabled={disabled}
                  target="_blank"
                  rel="noopener noreferrer"
                  iconName="ArrowTopRightOnSquareIconOutline"
                  title="Open with workspace"
                  {...(!disabled && { href: workspaceHref })}
                >
                  Open
                </Button>
              )}
            </OpenWithWorkspace>
          ) : (
            <>
              <OpenWithBloom database={database}>
                {({ onClick, href: bloomHref, disabled }) => (
                  <Button
                    fill="outlined"
                    className="bloom-button"
                    disabled={disabled}
                    iconName="bloom-app"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClick}
                    title="Explore your data in Neo4j Bloom"
                    {...(!disabled && { href: bloomHref })}
                  >
                    Explore
                  </Button>
                )}
              </OpenWithBloom>
              <OpenWithBrowser database={database}>
                {({ onClick, href: browserHref, disabled }) => (
                  <Button
                    fill="outlined"
                    className="browser-button"
                    disabled={disabled}
                    iconName="browser-app"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClick}
                    title="Query your data in Neo4j Browser"
                    {...(!disabled && { href: browserHref })}
                  >
                    Query
                  </Button>
                )}
              </OpenWithBrowser>
              <OpenWithImporter database={database}>
                {({ onClick, href: importerHref, disabled }) => (
                  <Button
                    fill="outlined"
                    className="importer-button"
                    disabled={disabled}
                    iconName="data-importer-app"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClick}
                    title="Import CSVs in Neo4j Data Importer"
                    {...(!disabled && { href: importerHref })}
                  >
                    Import
                  </Button>
                )}
              </OpenWithImporter>
            </>
          )}
        </div>
      </div>
      {[
        DatabaseStatus.ENCRYPTION_KEY_DELETED,
        DatabaseStatus.ENCRYPTION_KEY_ERROR,
        DatabaseStatus.ENCRYPTION_KEY_NOT_FOUND,
      ].includes(database.DatabaseStatus) && (
        <div>
          {database.DatabaseStatus === DatabaseStatus.ENCRYPTION_KEY_DELETED ? (
            <Alert
              type="danger"
              icon
              id="cmek-error-alert"
              className="tw-mt-5"
              data-testid="cmek-error-alert"
            >
              <p className="tw-text-sm">
                The encryption key used for this instance no longer exists in your cloud provider.
                As a result, Neo4j Aura has lost all read and write access to your data and the
                instance is not recoverable.
              </p>
            </Alert>
          ) : database.DatabaseStatus === DatabaseStatus.ENCRYPTION_KEY_ERROR ? (
            <Alert
              type="warning"
              icon
              id="cmek-error-alert"
              className="tw-mt-5"
              data-testid="cmek-error-alert"
            >
              <p className="tw-text-sm">
                There is an issue with the encryption key configured for this instance. The problem
                could be caused by various reasons, such as missing permissions for Neo4j Aura to
                use the key. Until the problem is resolved, the instance will remain non-functional
                and inaccessible.
              </p>
            </Alert>
          ) : (
            <Alert
              type="danger"
              icon
              id="cmek-error-alert"
              className="tw-mt-5"
              data-testid="cmek-error-alert"
            >
              <p className="tw-text-sm">
                The encryption key used for this instance could not be found in your cloud provider.
                As a result, Neo4j Aura has lost all read and write access to your data and the
                instance is not recoverable.
              </p>
            </Alert>
          )}
        </div>
      )}
      <div className="tw-flex tw-gap-x-6 tw-gap-y-3 tw-mt-12 tw-flex-wrap">
        <DbInfo
          title="Instance ID"
          value={
            <span className="tw-flex tw-h-2">
              {database.DbId}{' '}
              <CopyTextToClipBoardButton
                text={database.DbId}
                className="tw-ml-1 tw-mr-2"
                style={{ position: 'relative', bottom: '4px', height: 'fit-content' }}
                iconButtonProps={{ size: 'small', title: 'Copy instance ID' }}
              />
            </span>
          }
        />
        <DbInfo
          title="Neo4j version"
          value={getNeo4jVersionText(database.DesiredSettings.Version)}
        />
        <DbInfo
          title="Region"
          value={
            <div className="tw-flex tw-flex-row tw-gap-2">
              {friendlyRegionName(
                database,
                availableRegionsForTier(session.providerConfigs, database.Tier)
              )}
              <Icon name={regionIconName} height="16" title={friendlyCloudProviderName(database)} />
            </div>
          }
        />
        {database.Tier === Tier.FREE && (
          <>
            <DbInfo title="Nodes" value={asPercentageString(CurrentNodes, MaxNodes)} />
            <DbInfo
              title="Relationships"
              value={asPercentageString(CurrentRelationships, MaxRelationships)}
            />
          </>
        )}
        {database.Tier !== Tier.FREE && (
          <>
            <DbInfo title="Memory" value={getMemoryText(database)} />
            <DbInfo title="Storage" value={getStorageText(database)} />
            <DbInfo title="CPU" value={getCpuText(database)} />
          </>
        )}
        {showSecondaries && (
          <DbInfo title="Secondaries Count" value={getSecondariesCountText(database)} />
        )}
        {shouldDisplayCDCEnrichmentMode && (
          <DbInfo title="CDC Mode" value={getCDCEnrichmentModeText(database)} />
        )}
        {/* This is to make it work without the api changes */}
        {(database.PublicAccessEnabled ?? true) && (
          <DbInfo
            title="Connection URI"
            value={
              connectionUri ? (
                <span className="connection-uri tw-flex tw-h-2">
                  {connectionUri}{' '}
                  <CopyTextToClipBoardButton
                    text={connectionUri}
                    className="tw-ml-1 tw-mr-2"
                    style={{ position: 'relative', bottom: '4px', height: 'fit-content' }}
                    iconButtonProps={{ size: 'small', title: 'Copy URI' }}
                  />
                </span>
              ) : null
            }
          />
        )}
        {privateConnectionEnabled && (
          <DbInfo
            title="Private URI"
            value={
              privateBoltUrl ? (
                <span className="connection-uri tw-flex tw-h-2">
                  {privateBoltUrl}{' '}
                  <CopyTextToClipBoardButton
                    text={privateBoltUrl}
                    className="tw-ml-1 tw-mr-2"
                    style={{ position: 'relative', bottom: '4px', height: 'fit-content' }}
                    iconButtonProps={{ size: 'small', title: 'Copy URI' }}
                  />
                </span>
              ) : null
            }
          />
        )}
        {showCmekEnabled && (
          <DbInfo
            title="Encryption key"
            data-testid="encryption-key-info"
            value={
              !database.EncryptionKey
                ? 'Neo4j Managed Key'
                : getEncryptionKeyId(database.EncryptionKey)
            }
          />
        )}
      </div>
    </div>
  );
};

enum TabId {
  METRICS = 'metrics',
  SNAPSHOTS = 'snapshots',
  IMPORT = 'import',
  LOGS = 'logs',
}

const isDefaultTabEnabled = (database: Database) =>
  database.DatabaseStatus === DatabaseStatus.CREATING ||
  database.DatabaseStatus === DatabaseStatus.RUNNING ||
  database.DatabaseStatus === DatabaseStatus.RESTORING ||
  database.DatabaseStatus === DatabaseStatus.UPDATING ||
  database.DatabaseStatus === DatabaseStatus.LOADING_FAILED;

const isSnapshotsTabEnabled = (database: Database) =>
  // Paused databases don't get their snapshots back from the api, so
  // lets just disable that tab for now as a disabled tab is a better
  // ui than an empty list when there should be stuff there
  ![
    DatabaseStatus.DESTROYING,
    DatabaseStatus.DESTROYED,
    DatabaseStatus.PAUSED,
    DatabaseStatus.PAUSING,
  ].includes(database.DatabaseStatus);

const isTabEnabledMap: Record<TabId, (d: Database) => boolean> = {
  [TabId.METRICS]: isDefaultTabEnabled,
  [TabId.IMPORT]: isDefaultTabEnabled,
  [TabId.LOGS]: isDefaultTabEnabled,
  [TabId.SNAPSHOTS]: isSnapshotsTabEnabled,
};

const isTabEnabled = (database: Database, tabId: TabId) => isTabEnabledMap[tabId](database);

const getFirstTabEnabled = (tabOrder: TabId[], database: Database): TabId | undefined =>
  tabOrder.find(t => isTabEnabled(database, t));
const getTabs = (database: Database): TabId[] => {
  const showMetricsTab = database.Tier !== Tier.FREE;

  const tabs = [];
  if (showMetricsTab) {
    tabs.push(TabId.METRICS);
  }

  tabs.push(TabId.SNAPSHOTS, TabId.IMPORT, TabId.LOGS);

  return tabs;
};

interface DatabaseDetailsProps {
  database: Database;
  snapshots?: Snapshot[];
}

const MigrationAlert = ({ database, session }: { database: Database; session: SessionState }) => {
  const DISMISS_MIGRATION_ALERT_KEY = `dismissMigrationAlert-${database.DbId}`;
  const [migrationAlertExpiry, setMigrationAlertExpiry] = useState<number>(
    parseInt(window.localStorage.getItem(DISMISS_MIGRATION_ALERT_KEY) || '0', 10)
  );
  const now = useMemo(() => new Date(), []);
  const migrationAlertVisible = useMemo(
    () =>
      session.featureToggles.enable_migration_assistant &&
      isMigrationAssistantAccessible(database) &&
      needsMigration(database.DesiredSettings.Version) &&
      migrationAlertExpiry < now.getTime(),
    [session, database, migrationAlertExpiry, now]
  );
  return (
    migrationAlertVisible && (
      <Alert
        className="tw-mb-3"
        icon
        data-testid="database-migration-alert"
        title="Migration Recommended"
        type="warning"
        actions={[
          {
            label: 'Migration Readiness',
            onClick: () => handleMigrationAssistantClick(database),
          },
          {
            label: 'Remind me in one week',
            onClick: () => {
              const ttl = 7 * 24 * 60 * 60 * 1000; // one week
              const newExpiry = now.getTime() + ttl;
              window.localStorage.setItem(DISMISS_MIGRATION_ALERT_KEY, `${newExpiry}`);
              setMigrationAlertExpiry(newExpiry);
            },
          },
        ]}
      >
        This instance is using an older version of AuraDB. We suggest you upgrade to the latest
        version of AuraDB to take advantage of the latest features and services.
      </Alert>
    )
  );
};

export const DatabaseDetailPage = ({ database, snapshots }: DatabaseDetailsProps) => {
  const session = useSession();
  const { allow } = usePermissions();
  const allowImport = allow(
    Action.OVERWRITE,
    `namespaces/${session.currentTenant}/databases/${database.DbId}`
  );
  const tabs = useMemo(() => getTabs(database), [database]);
  const [selectedTab, setSelectedTab] = useState<TabId | undefined>(() =>
    getFirstTabEnabled(tabs, database)
  );

  // Default to no tab selected if all tabs are disabled
  let shownTab = selectedTab ?? 'unknown';

  const showUpgradeForLogs = database.Tier === Tier.FREE;

  useEffect(() => {
    const enabledTabs = tabs.filter(t => isTabEnabled(database, t));
    if (!enabledTabs.includes(selectedTab)) {
      setSelectedTab(getFirstTabEnabled(tabs, database));
    }
  }, [database]);

  return (
    <Page plain fullWidth>
      <div className="tw-flex tw-flex-col tw-mt-4">
        <DetailsHeader database={database} session={session} />
        <MigrationAlert database={database} session={session} />
        <Tabs<TabId>
          // Ya 'unknown' not a TabId and could be the value, but its fine that just means nothing will be selected
          value={shownTab as TabId}
          onChange={setSelectedTab}
          data-testid="db-detail-tabs"
        >
          {tabs.includes(TabId.METRICS) && (
            <Tabs.Tab
              disabled={!isTabEnabled(database, TabId.METRICS)}
              tabId={TabId.METRICS}
              data-appcues-id="db-details-tab-metrics"
            >
              Metrics
            </Tabs.Tab>
          )}
          <Tabs.Tab
            disabled={!isTabEnabled(database, TabId.SNAPSHOTS)}
            tabId={TabId.SNAPSHOTS}
            data-appcues-id="db-details-tab-snapshots"
          >
            Snapshots
          </Tabs.Tab>
          <Tabs.Tab
            disabled={!isTabEnabled(database, TabId.IMPORT) || !allowImport}
            tabId={TabId.IMPORT}
            data-appcues-id="db-details-tab-import"
            data-testid="db-details-import-tab"
          >
            <PermissionTip hasPermission={allowImport}>
              <p>Import Database</p>
            </PermissionTip>
          </Tabs.Tab>
          {tabs.includes(TabId.LOGS) && (
            <Tabs.Tab
              disabled={!isTabEnabled(database, TabId.LOGS)}
              tabId={TabId.LOGS}
              data-appcues-id="db-details-tab-logs"
            >
              Logs
            </Tabs.Tab>
          )}
        </Tabs>
        {selectedTab && (
          <div
            className={TabId.METRICS !== shownTab ? 'details-tab-panels' : 'tw-py-6'}
            data-testid="details-tab-panels"
          >
            {tabs.includes(TabId.METRICS) && (
              <Tabs.TabPanel value={shownTab} tabId={TabId.METRICS}>
                <DatabaseMetricChartsSection
                  dbId={database.DbId}
                  opsManagerUrl={database.OpsManagerUrl}
                />
              </Tabs.TabPanel>
            )}
            <Tabs.TabPanel value={shownTab} tabId={TabId.SNAPSHOTS}>
              <SnapshotSection database={database} snapshots={snapshots} session={session} />
            </Tabs.TabPanel>
            <Tabs.TabPanel value={shownTab} tabId={TabId.IMPORT}>
              <Import database={database} />
            </Tabs.TabPanel>
            {tabs.includes(TabId.LOGS) && (
              <Tabs.TabPanel value={shownTab} tabId={TabId.LOGS}>
                {showUpgradeForLogs && (
                  <div className="tw-flex tw-flex-col tw-items-center tw-my-4">
                    <p>
                      Logs are only available for AuraDB Professional instances. Clone to, or create
                      one of these instances to get logs.
                      <br />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://neo4j.com/docs/aura/auradb/managing-databases/database-actions/#_cloning_an_instance"
                      >
                        Learn more
                      </a>
                    </p>
                  </div>
                )}
                {!showUpgradeForLogs && <LogsSection database={database} />}
              </Tabs.TabPanel>
            )}
          </div>
        )}
      </div>
    </Page>
  );
};

const DatabaseDetailPageGuarded = ({ dbId }: { dbId: string }) => {
  const session = useSession();
  const dbRequest = useDatabaseRequest(dbId);
  const snapRequest = useSnapshotsRequest(dbId, { ignore: IgnoreErrors.ALL });

  useDatabasePoll(dbId, { ignore: IgnoreErrors.ALL });
  useSnapshotsPoll(dbId, { ignore: IgnoreErrors.ALL });

  const selectDatabase = useCallback(selectDatabaseFactory(dbId), [dbId]);
  const database = useSelector(selectDatabase);
  const selectSnapshots = useCallback(selectDatabaseSnapshotsFactory(dbId), [dbId]);
  const snapshots = useSelector(selectSnapshots);

  if (dbRequest.loading || snapRequest.loading) {
    return <LoadingSpinner size="large" expand />;
  }

  if ((dbRequest.error as any)?.response?.status === 404) {
    Actions.navigate.push({ hash: 'not-found' });
    return null;
  }

  if (dbRequest.error) {
    return <LoadError />;
  }

  if (!database || database.NamespaceId !== session.currentTenant) {
    return (
      <Page className="tw-p-8" data-testid="instance-not-found">
        <h2 className="tw-pb-4">Instance not found</h2>
        <p>
          Instance <code>{dbId}</code> could not be found in this tenant. Make sure that the
          Instance ID is correct and you are in the correct tenant.
        </p>
      </Page>
    );
  }

  return <DatabaseDetailPage database={database} snapshots={snapshots} />;
};

export default track({ page: 'databases/detail' })(DatabaseDetailPageGuarded);
