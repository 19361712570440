import ApiClient from 'remote/api-client';
import { Usage, DateFilter, Filter, FilterType } from 'types/usage';
import { format, startOfDay, endOfDay, isToday, subDays } from 'date-fns';
import { MAXIMUM_DAYS } from 'components/application/usage/const';

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

const getFormattedDateRange = (filters: Filter[] | DateFilter[]) => {
  const now = new Date();
  const dateFilter = filters.find(filter => filter.type === FilterType.DATE) as DateFilter;
  const start = startOfDay(dateFilter ? dateFilter.start : subDays(now, MAXIMUM_DAYS - 1));
  const end =
    dateFilter && dateFilter.end && !isToday(dateFilter.end) ? endOfDay(dateFilter.end) : null;
  return {
    start: encodeURI(format(start, DATE_FORMAT)),
    end: end ? encodeURI(format(end, DATE_FORMAT)) : null,
  };
};

const get = (tenantId: string, filters?: Filter[] | DateFilter[]): Promise<Usage> => {
  let encodedDateFilter = null;
  if (filters && filters.length !== 0) {
    const { start, end } = getFormattedDateRange(filters);
    encodedDateFilter = `?StartDate=${start}${end ? '&EndDate=' + end : ''}`; // in case of custom range end date is not null
  }
  return ApiClient.get(`/tenants/${tenantId}/usage${encodedDateFilter || ''}`).promise();
};

export default { get };
