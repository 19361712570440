import React from 'react';
import ProfessionalForm, {
  validate as validateProfessional,
  defaults as professionalDefaults,
} from './form-professional';
import EnterpriseForm, {
  validate as validateEnterprise,
  defaults as enterpriseDefaults,
} from './form-enterprise';
import DataScienceForm, { validate as validateDse, defaults as dseDefaults } from './form-dse';
import GdsForm, { validate as validateGds, defaults as gdsDefaults } from './form-gds';
import { Product } from 'types/product';
import { CreateDatabaseFormData, CreateDatabaseFormOptions } from './form-data';
import { Validation } from 'utils/validation';
import { Tier } from 'entities/database';
import { PlanType } from 'entities/tenant';
import { validate as validateFree, defaults as freeDefaults } from './free-tier';
import { getDefaultSelfServeDatabaseName } from 'components/utils';

export const validate = (
  data: CreateDatabaseFormData,
  options: CreateDatabaseFormOptions,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => {
  if (options.product === Product.AURA_DS) {
    if (data.tier === Tier.AURA_DSE) {
      return validateDse(data, { onlyRequired });
    } else {
      return validateGds(data, { onlyRequired });
    }
  }
  if (options.product === Product.AURA_DB) {
    if (options.planType === PlanType.SELF_SERVE) {
      if (data.tier === Tier.PROFESSIONAL) {
        return validateProfessional(data, { onlyRequired });
      } else {
        return validateFree(data, { onlyRequired });
      }
    }
    if (options.planType === PlanType.ENTERPRISE) {
      return validateEnterprise(data, { onlyRequired });
    }
  }
  throw new Error(`Invalid planType ${options.planType} or product ${options.product}`);
};

export const defaultsForTier = (
  options: CreateDatabaseFormOptions,
  tier: Tier
): CreateDatabaseFormData => {
  switch (tier) {
    case Tier.FREE:
      return freeDefaults(options.providerConfigs);
    case Tier.PROFESSIONAL:
      return professionalDefaults();
    case Tier.ENTERPRISE:
      return enterpriseDefaults(options);
    case Tier.MTE: {
      let data = enterpriseDefaults(options);
      data.name = getDefaultSelfServeDatabaseName();
      return data;
    }
    case Tier.AURA_DSE:
      return dseDefaults(options);
    case Tier.GDS:
      return gdsDefaults(options);
  }
};

interface Props {
  data: CreateDatabaseFormData;
  options: CreateDatabaseFormOptions;
  onChange: (data: CreateDatabaseFormData, submit?: boolean) => any;
  validation: Validation<CreateDatabaseFormData>;
  disabled?: boolean;
}

const CreateDatabaseForm = ({ options, data, onChange, validation, disabled }: Props) => {
  const handleChange = (newData: CreateDatabaseFormData, submit?: boolean) => {
    if (data.tier !== newData.tier) {
      onChange(defaultsForTier(options, newData.tier), submit);
    } else {
      onChange(newData, submit);
    }
  };

  if (options.product === Product.AURA_DS) {
    if (data.tier === Tier.AURA_DSE) {
      return (
        <DataScienceForm
          data={data}
          options={options}
          onChange={onChange}
          validation={validation}
          disabled={disabled}
        />
      );
    } else {
      return (
        <GdsForm
          data={data}
          options={options}
          onChange={onChange}
          validation={validation}
          disabled={disabled}
        />
      );
    }
  }
  if (options.product === Product.AURA_DB) {
    if (options.planType === PlanType.ENTERPRISE) {
      return (
        <EnterpriseForm
          data={data}
          options={options}
          onChange={onChange}
          validation={validation}
          disabled={disabled}
        />
      );
    }
    if (options.planType === PlanType.SELF_SERVE) {
      // We reuse the ProfessionalForm for MTE as well
      // TODO: can we make this less hacky?
      return (
        <ProfessionalForm
          options={options}
          data={data}
          onChange={handleChange}
          validation={validation}
          disabled={disabled}
        />
      );
    }
  }
  throw new Error(`Invalid planType ${options.planType} or product ${options.product}`);
};

export default CreateDatabaseForm;
