import React, { useState } from 'react';
import { FormInput, Alert, Button, Form } from 'foundation';
import UsersResource from 'remote/resources/users';
import { UsersTable } from './users-table';
import { User } from 'types/user';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';

export const LookupUsersForm = () => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [users, setUsers] = useState<User[]>([]);

  const handleQueryChange = ({ target: { value } }) => setQuery(value);

  const handleSearchByEmailSubmit = async () => {
    if (!query) return;
    setLoading(true);
    setUsers([]);
    setError(null);

    try {
      const result = await UsersResource.search(query.trim());
      setUsers(result);
      if (result.length === 0) {
        setError(`No users found with Email/UserId: ${query}`);
      }
    } catch (err) {
      if (err instanceof ApiClientRequestError && err.response.status === 404) {
        setError(`No user found with Email/UserId: ${query}`);
      } else {
        setError(String(err));
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Form onSubmit={handleSearchByEmailSubmit}>
        <FormInput
          fluid
          value={query}
          onChange={handleQueryChange}
          placeholder="User ID or email (case-sensitive)"
          aria-label="User ID or email"
          data-testid="lookup-user-by-query-input"
          autoComplete="email"
        />
        <Button
          className="tw-mt-4"
          type="submit"
          color="primary"
          loading={loading}
          disabled={query === '' || loading}
          data-testid="lookup-user-by-query-button"
        >
          Find by email / User ID
        </Button>
        {error && (
          <Alert type="danger" data-testid="lookup-user-error-message" className="tw-mt-2">
            {error}
          </Alert>
        )}
      </Form>

      {users.length > 0 && (
        <div className="tw-mt-4">
          <UsersTable users={users} />
        </div>
      )}
    </>
  );
};
