import { Tenant } from 'entities/tenant';
import logger from 'logger';

export enum RoleName {
  CUSTOMER_SUPPORT = 'customer-support',
  CUSTOMER_SUPPORT_VIEWER = 'customer-support-viewer',
  CONSOLE_CLI = 'console-cli',
  SRE_PORTAL = 'sre-portal',
  AURA_API = 'aura-api',
  TENANT_VIEWER = 'namespace-viewer',
  TENANT_MEMBER = 'namespace-member',
  TENANT_ADMIN = 'namespace-admin',
  TENANT_METRICS_INTEGRATION_READER = 'namespace-metrics-integration-reader',
  ORGANIZATION_OWNER = 'organization-owner',
  ORGANIZATION_ADMIN = 'organization-admin',
  ORGANIZATION_MEMBER = 'organization-member',
}

export const TENANT_ROLES = [
  RoleName.TENANT_ADMIN,
  RoleName.TENANT_MEMBER,
  RoleName.TENANT_VIEWER,
  RoleName.TENANT_METRICS_INTEGRATION_READER,
];

const TENANT_ROLES_FRIENDLY_NAME = {
  [RoleName.TENANT_ADMIN]: 'Admin',
  [RoleName.TENANT_MEMBER]: 'Member',
  [RoleName.TENANT_VIEWER]: 'Viewer',
  [RoleName.TENANT_METRICS_INTEGRATION_READER]: 'Metrics Integration Reader',
};

export const ORGANIZATION_ROLES = [
  RoleName.ORGANIZATION_OWNER,
  RoleName.ORGANIZATION_ADMIN,
  RoleName.ORGANIZATION_MEMBER,
];

const ORGANIZATION_ROLES_FRIENDLY_NAME = {
  [RoleName.ORGANIZATION_OWNER]: 'Owner',
  [RoleName.ORGANIZATION_ADMIN]: 'Admin',
  [RoleName.ORGANIZATION_MEMBER]: 'Member',
};

export const tenantRoleToFriendlyName = (tenantRole: RoleName) => {
  return TENANT_ROLES_FRIENDLY_NAME[tenantRole];
};

export const organizationRoleToFriendlyName = (organizationRole: RoleName) => {
  return ORGANIZATION_ROLES_FRIENDLY_NAME[organizationRole];
};

const ROLES_REQUIRING_ORGANIZATION_OPTIONS: RoleOption[] = [
  {
    label: 'Member',
    key: RoleName.ORGANIZATION_MEMBER,
    value: RoleName.ORGANIZATION_MEMBER,
  },
  {
    label: 'Admin',
    key: RoleName.ORGANIZATION_ADMIN,
    value: RoleName.ORGANIZATION_ADMIN,
  },
  {
    label: 'Owner',
    key: RoleName.ORGANIZATION_OWNER,
    value: RoleName.ORGANIZATION_OWNER,
  },
];

export const getOrganizationRoleOptions = () => {
  return ROLES_REQUIRING_ORGANIZATION_OPTIONS;
};

export const getTenantRoleOptions = (tenant: Tenant) => {
  return tenant.availableRoles.map(role => ({
    label: tenantRoleToFriendlyName(role),
    key: role,
    value: role,
  }));
};

export const isTenantRole = (role: RoleName) => {
  return TENANT_ROLES.includes(role);
};

export const isOrganizationRole = (role: RoleName) => {
  return ORGANIZATION_ROLES.includes(role);
};

export const isGlobalRole = (role: RoleName) => !isTenantRole(role) && !isOrganizationRole(role);

export const isInternalRole = (role: RoleName) => {
  switch (role) {
    case RoleName.CONSOLE_CLI:
    case RoleName.CUSTOMER_SUPPORT:
    case RoleName.CUSTOMER_SUPPORT_VIEWER:
    case RoleName.SRE_PORTAL:
      return true;
    case RoleName.TENANT_ADMIN:
    case RoleName.TENANT_MEMBER:
    case RoleName.TENANT_VIEWER:
    case RoleName.AURA_API:
    case RoleName.TENANT_METRICS_INTEGRATION_READER:
      return false;
    default:
      logger.debug('Internal role is not specified');
      return true;
  }
};

export interface Role {
  RoleId: string;
  UserId: string;
  Name: RoleName;
  NamespaceId?: string;
  OrganizationId?: string;
}

export enum Action {
  READ = 'read',
  PUT = 'put',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  PAUSE = 'pause',
  RESUME = 'resume',
  TRANSFER = 'transfer',
  OVERWRITE = 'overwrite',
  SUSPEND = 'suspend',
}

export interface Permission {
  Action: Action;
  Resource: string;
}

export interface User {
  AcceptedTermsAndConditions: string;
  Alias: string;
  CreatedAt: string;
  Email: string;
  Namespaces: string[];
  DefaultNamespace: string;
  Terminated: boolean;
  UserId: string;
  Permissions: Permission[];
  Organizations: string[];
}

export interface TenantUserRole {
  NamespaceId: string;
  Name: string;
  RoleId: string;
  UserId: string;
}

export interface PartnerAccount {
  userId: string;
  projectId: string;
}
export interface RoleOption {
  label: string;
  key: RoleName;
  value: RoleName;
}

interface MemberBaseRole {
  UserId?: string;
  Email: string;
  Roles: Role[];
}

export type TenantUser = MemberBaseRole & { NamespaceId: string };

export type OrganizationMember = MemberBaseRole & { OrganizationId: string };

export type Member = TenantUser | OrganizationMember;

export enum RegionalGDPRComplianceTier {
  TIER_1 = 'tier-1',
  TIER_2 = 'tier-2',
  TIER_3 = 'tier-3',
}
