import React, { ChangeEvent, useState } from 'react';
import { FormInput, FormSelect } from 'foundation/form-element';
import { Switch } from 'foundation/switch';
import * as yup from 'yup';
import { validateYup, Validation } from 'utils/validation';
import './create-tenant-form.css';
import { Button } from '@neo4j-ndl/react';
import Icon from 'components/ui/icons';
import { TenantProfile } from 'entities/tenant';
import { tenantProfileOptions } from '../constants';

export interface CreateTenantFormData {
  internalName: string;
  isolationIds: string[];
  requireMFA: boolean;
  friendlyName: string;
  salesforceCppId: string | undefined;
  minimumNeo4jVersion: string;
  tenantProfile: TenantProfile | undefined;
  organizationId: string;
}

interface Props {
  data: CreateTenantFormData;
  onChange: (data: CreateTenantFormData) => any;
  validation: Validation<CreateTenantFormData>;
  disabled?: boolean;
  lockedOrganizationId?: string;
}

const schema = yup.object().shape({
  internalName: yup.string().required('Please enter a tenant ID'),
  isolationIds: yup
    .array()
    .required()
    .test('Non empty isolation IDs', 'Please provide at least one Isolation ID', value =>
      value.some(elem => elem.trim().length > 0)
    )
    .max(2, 'Please provide no more than two isolation IDs'),
  friendlyName: yup
    .string()
    .max(30)
    .required('Please enter a friendly name'),
  organizationId: yup.string().required('Please enter an Organization UUID'),
  tenantProfile: yup.string().required('Please select a tenant profile'),
});

export const validate = (data: CreateTenantFormData): Validation<CreateTenantFormData> =>
  validateYup(schema, {
    internalName: data.internalName,
    friendlyName: data.friendlyName,
    isolationIds: data.isolationIds,
    salesforceCppId: data.salesforceCppId,
    organizationId: data.organizationId,
    tenantProfile: data.tenantProfile,
  });

export const defaults = (): CreateTenantFormData => {
  return {
    internalName: '',
    isolationIds: [''],
    requireMFA: false,
    friendlyName: '',
    salesforceCppId: '',
    minimumNeo4jVersion: '5',
    tenantProfile: undefined,
    organizationId: '',
  };
};

const CreateTenantFormFields = ({
  data,
  onChange,
  validation,
  disabled,
  lockedOrganizationId,
}: Props) => {
  const [MFAEnabled, setMFAEnabled] = useState(false);
  const handleInternalNameChange = ({
    target: { value: internalName },
  }: ChangeEvent<HTMLInputElement>) => onChange({ ...data, internalName });

  const organizationIdFieldDisabled =
    lockedOrganizationId !== '' && data.organizationId === lockedOrganizationId;
  const addIsolationIdFieldDisabled = data.isolationIds.length >= 2;

  const handleIsolationIdChange = (event: ChangeEvent<HTMLInputElement>, index: number) =>
    onChange({
      ...data,
      isolationIds: data.isolationIds.map((id, i) => {
        if (i === index) {
          return event.target.value;
        } else {
          return id;
        }
      }),
    });

  const handleFriendlyNameChange = ({
    target: { value: friendlyName },
  }: ChangeEvent<HTMLInputElement>) => onChange({ ...data, friendlyName });

  const handleMFAChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMFAEnabled(e.target.checked);
    onChange({ ...data, requireMFA: e.target.checked });
  };

  const addIsolationIdField = () => onChange({ ...data, isolationIds: [...data.isolationIds, ''] });

  const removeIsolationIdField = (index: number) =>
    onChange({
      ...data,
      isolationIds: data.isolationIds.filter((_, i) => i !== index),
    });

  const handleSalesforceCppIdChange = ({ target: { value } }) => {
    onChange({ ...data, salesforceCppId: value });
  };

  const handleMinimumVersionChange = ({ value }) =>
    onChange({ ...data, minimumNeo4jVersion: value });

  const handleTenantProfileChange = ({ value }) => onChange({ ...data, tenantProfile: value });

  const handleOrganizationIdChange = ({
    target: { value: organizationId },
  }: ChangeEvent<HTMLInputElement>) => onChange({ ...data, organizationId });

  return (
    <>
      <div className="tw-my-4">
        <h6>Tenant ID</h6>
        <FormInput
          fluid
          aria-label="Tenant internal name"
          data-testid="create-tenant-internal-name-input"
          value={data.internalName}
          onChange={handleInternalNameChange}
          errorText={validation?.internalName?.message}
          disabled={disabled}
        />
        <div className="form-field-hint n-body-medium tw-mt-4">
          This should match the <code>tenant ID</code> you decided to use in the{' '}
          <a
            href="https://docs.google.com/spreadsheets/d/1cq9BQFYPJg5K18lNt8ztHUC6wXB8IpvFAF5MS9cF16Y/edit"
            target="_blank"
            rel="noopener noreferrer"
          >
            VDC Environments &amp; Users spreadsheet
          </a>
          . Example: <code>acmecorp</code>.
        </div>
      </div>

      <div className="tw-my-10">
        <h6>Isolation IDs</h6>
        {data.isolationIds.map((_, index) => (
          <FormInput
            key={index}
            className={index > 0 && 'tw-mt-2'}
            fluid
            aria-label="Isolation id"
            data-testid={`create-tenant-isolation-id-input-${index + 1}`}
            value={data.isolationIds[index]}
            onChange={event => handleIsolationIdChange(event, index)}
            errorText={validation?.isolationIds?.message}
            disabled={disabled}
            rightIcon={
              index > 0 && (
                <Icon
                  name="TrashIconOutline"
                  onClick={() => removeIsolationIdField(index)}
                  data-testid={`remove-isolation-id-button-${index + 1}`}
                />
              )
            }
          />
        ))}
        <Button
          color="neutral"
          className="tw-mt-2"
          fill="outlined"
          onClick={addIsolationIdField}
          disabled={disabled || addIsolationIdFieldDisabled}
          data-testid="add-isolation-id-textfield-button"
        >
          Add Isolation ID
        </Button>
        <div className="form-field-hint n-body-medium tw-mt-4">
          Example <code>ni-xxxx-yyy</code> for GCP or a numerical ID for AWS. The ID can be found in
          the GitHub Pull Request with the new isolation unit configuration, as provisioned by the
          platform team.
        </div>
      </div>

      <div className="tw-my-10">
        <h6>Friendly Name</h6>
        <FormInput
          fluid
          aria-label="Tenant friendly name"
          data-testid="create-tenant-friendly-name-input"
          value={data.friendlyName}
          onChange={handleFriendlyNameChange}
          errorText={validation?.friendlyName?.message}
          disabled={disabled}
        />
      </div>

      <div className="tw-my-10">
        <h6>Minimum Neo4j Database Version</h6>
        <FormSelect
          data-testid="minimum-neo4j-version"
          fluid
          options={[
            { label: '4', value: '4', key: '4' },
            { label: '5', value: '5', key: '5' },
          ]}
          value={data.minimumNeo4jVersion}
          onChange={handleMinimumVersionChange}
          disabled={disabled}
          helpText="The minimum Neo4j Database version which users in this tenant can use for creating Instances. Please only select 4 if it is explicitly required by the customer."
        />
      </div>

      <div className="tw-my-10">
        <FormSelect
          data-testid="tenant-profile"
          fluid
          options={tenantProfileOptions.map(option => ({ ...option }))}
          value={data.tenantProfile}
          onChange={handleTenantProfileChange}
          defaultValue={null}
          disabled={disabled}
          label={<h6 className="tw-text-palette-neutral-text-default">Tenant Profile</h6>}
          errorText={validation?.tenantProfile?.message}
          helpText="External tenant instances will be created with a high priority, while Internal instances will have a lower priority and features enabled for internal users
                    will be available. Select External for external customer tenants and Internal for internal employee tenants."
        />
      </div>
      <div className="tw-my-10">
        <h6>Organization UUID</h6>
        <FormInput
          fluid
          aria-label="Organization ID"
          data-testid="create-tenant-organization-id-input"
          value={data.organizationId}
          onChange={handleOrganizationIdChange}
          errorText={validation?.organizationId?.message}
          disabled={disabled || organizationIdFieldDisabled}
          helpText="The UUID of the Organization this tenant belongs to."
        />
      </div>

      <div className="tw-my-10">
        <h6>Require MFA</h6>
        <Switch
          data-testid="enable-tenant-mfa-switch"
          checked={MFAEnabled}
          onChange={handleMFAChange}
          disabled={disabled}
          label="Require tenant users to use multi-factor authentication."
        />
      </div>
      <div className="tw-my-10">
        <h6>Salesforce Customer Project Profile ID</h6>
        <FormInput
          data-testid="salesforce-customer-project-profile-id-input"
          fluid
          value={data.salesforceCppId}
          onChange={handleSalesforceCppIdChange}
          disabled={disabled}
          helpText="If provided, users will be automatically added to this Salesforce customer project profile when added to this tenant."
          aria-label="Salesforce Customer Project Profile ID"
        />
      </div>
    </>
  );
};

export default CreateTenantFormFields;
