import React from 'react';

import { Page } from 'foundation/page';

const GdprPage = () => (
  <Page>
    <div
      className="tw-rounded-lg tw-p-4 tw-mb-4"
      style={{ border: '1px solid rgba(34, 36, 38, 0.15)' }}
    >
      <div>
        <h6 className="tw-inline tw-mr-2">Entity Name:</h6>
        <span>Google, LLC</span>
      </div>
      <div>
        <h6 className="tw-inline tw-mr-2">Purpose:</h6>
        <span>
          Infrastructure provider for Neo4j Aura, including storage of encrypted databases, backups,
          and logfiles.
        </span>
      </div>
      <div>
        <h6 className="tw-inline tw-mr-2">Link:</h6>
        <a
          href="https://cloud.google.com/terms/subprocessors"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://cloud.google.com/terms/subprocessors
        </a>
      </div>
    </div>

    <div
      className="tw-rounded-lg tw-p-4 tw-mt-4"
      style={{ border: '1px solid rgba(34, 36, 38, 0.15)' }}
    >
      <div>
        <h6 className="tw-inline tw-mr-2">Entity Name:</h6>
        <span>Amazon Web Services, Inc</span>
      </div>
      <div>
        <h6 className="tw-inline tw-mr-2">Purpose:</h6>
        <span>
          Infrastructure provider for Neo4j Aura, including storage of encrypted databases, backups,
          and logfiles.
        </span>
      </div>
      <div>
        <h6 className="tw-inline tw-mr-2">Link:</h6>
        <a
          href="https://aws.amazon.com/compliance/sub-processors/"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://aws.amazon.com/compliance/sub-processors/
        </a>
      </div>
    </div>
  </Page>
);

export default GdprPage;
