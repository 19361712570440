import {
  getDatabaseOnlineStatus,
  OnlineStatus,
  DatabaseStatus as DbStatus,
  Database,
  Tier,
} from 'entities/database';

import logger from 'logger';
import React from 'react';
import { SessionState } from 'state/session-store';

export interface DbStatusTextProps {
  database: Database;
  session: SessionState;
}

const dbStatusText = ({ database, session }: DbStatusTextProps) => {
  // TODO: ideally this wouldn't be based on tier.
  // It would be better if it were a property of the database entity,
  // either persisted or computed/derived.
  const isSingleInstance = [Tier.FREE, Tier.GDS, Tier.AURA_DSE].includes(database.Tier);
  switch (database.DatabaseStatus) {
    case DbStatus.UPDATING:
      if (
        database.DesiredSettings.Memory &&
        database.AppliedSettings.Memory !== database.DesiredSettings.Memory
      ) {
        return 'resizing instance';
      }
      if (
        database.DesiredSettings.SecondariesCount !== undefined &&
        database.AppliedSettings.SecondariesCount !== database.DesiredSettings.SecondariesCount
      ) {
        return 'updating secondaries count';
      }
      if (
        database.DesiredSettings.CDCEnrichmentMode &&
        database.AppliedSettings.CDCEnrichmentMode !== database.DesiredSettings.CDCEnrichmentMode
      ) {
        return 'updating CDC mode';
      }
      if (isSingleInstance) {
        // Single instances go offline during updates, so we should
        // tell users about it
        return 'updating';
      }

    // Otherwise it's a rolling update to a cluster, which we don't tell users
    // about. In theory it should be indistinguishable from the Running state.
    // Falls through here to RUNNING case to make that link more explicit.
    case DbStatus.RUNNING:
      break;

    case DbStatus.PAUSING:
      return session.tenant.suspended ? DbStatus.SUSPENDING : DbStatus.PAUSING;

    case DbStatus.PAUSED:
      return session.tenant.suspended ? DbStatus.SUSPENDED : DbStatus.PAUSED;

    case DbStatus.CREATING:
      return (
        <span className="setting-up-text">
          Setting up your instance
          <em>(this will take a few minutes)</em>
        </span>
      );
    default:
      return database.DatabaseStatus;
  }

  const onlineStatus = getDatabaseOnlineStatus(database);

  switch (onlineStatus) {
    case OnlineStatus.ONLINE:
      return DbStatus.RUNNING;
    case OnlineStatus.DEGRADED:
    case OnlineStatus.OFFLINE:
      return onlineStatus;
    default:
      logger.warn('Unhandled DatabaseEntity.databaseOnlineStatus():', onlineStatus);
      return OnlineStatus.OFFLINE;
  }
};

export default dbStatusText;
