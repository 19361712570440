import ApiClient from 'remote/api-client';
import { GdsSession } from 'types/gds-session';

const transformGdsSessionsResponse = (data: any): GdsSession => {
  return {
    sessionId: data.id,
    dbId: data.instance_id,
    userId: data.user_id,
    sessionName: data.name,
    memory: data.memory,
    status: data.status,
    createdAt: data.created_at,
    host: data.host,
    expiryDate: data.expiry_date,
    ttl: data.ttl,
  };
};

export const list = async (dbId: string): Promise<GdsSession[]> => {
  const sessions = await ApiClient.get('/data-science/sessions')
    .query({ instanceId: dbId })
    .ignoreAllErrors()
    .promise()
    .then(response => response.data);

  return sessions.map(transformGdsSessionsResponse);
};

export const destroy = (dbId: string, sessionId: string) =>
  ApiClient.delete(`/data-science/sessions/${sessionId}`)
    .issue({ instance_id: dbId })
    .ignoreAllErrors()
    .promise();

export default {
  list,
  destroy,
};
