import Actions from 'actions';
import partnerPunchoutHelper from './partnerPunchoutHelper';

export const FAKE_JWT =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik5FVkJPRFpDTURWRVF6bEdOVGRFUlRaRU1VUkJSakEzUWpKR01EQTJNMEU1TmtKRE1FSkJOQSJ9.eyJuZW80ai9yb2xlcyI6W10sImlzcyI6Imh0dHBzOi8vYWNjb3VudC1kZXYubmVvNGouY29tLyIsInN1YiI6Im9pZGN8c3NvLW5ld29yZzEtYW51MnwwMHVpbWFuZ2dyQkJtYllsVTVkNyIsImF1ZCI6WyJodHRwczovL25vbnByb2QubmVvNGouaW8iLCJodHRwczovL25lbzRqLWRldi5ldS5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNzIyNTU3NDMyLCJleHAiOjE3MjI2NDM4MzIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwiLCJvcmdfaWQiOiJvcmdfMDNRcEtNUW4yVkJldnlObiIsImF6cCI6ImU5b3lSelY5TFYwQlNPWFpsaG5yVnoxOWgwTEU4NmNUIn0.n3RAqyQ-BlLoc9mAswj_5Rb1VLPz8g3TWVh1YbpGarGHkjjKobiFp_WDxwC5napSs5Ti7EUsHh2w2IuWGPCqU5x1nmmYg4Sx2NAZHnHhdV0q4rgDfshjR0xIUaAQyz2xmn3Za3PUymuGl0rWfU_kBaA44XexRnIQA5qugKNCoM1GAMxdgT3EYtcu3DTSjgqs6T7CF1rG4M7uypfZYFqwgo3LfF3JPJoNv9lXMDUX9IRx1p3DmEgXcaCZrKC0NCyQWraRMIf4QrAetdmXNCJzaHB2jHR_7AW_IWeRxg4EU8cb-KykJvUyl3Q-ckjnESokG1fAetCCx9xSqNdGy82tVg';

export const FAKE_ORG_JWT =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik5FVkJPRFpDTURWRVF6bEdOVGRFUlRaRU1VUkJSakEzUWpKR01EQTJNMEU1TmtKRE1FSkJOQSJ9.eyJuZW80ai9yb2xlcyI6W10sImlzcyI6Imh0dHBzOi8vYWNjb3VudC1kZXYubmVvNGouY29tLyIsInN1YiI6Im9pZGN8YnJ5Y2Utc3NvLXRlc3R8WEV1LXBKMEZmRE1xQ2FOX00wUEhzdDk3TkNxMkoyWVJqWDVNcjBIWld4ZyIsImF1ZCI6WyJodHRwczovL25vbnByb2QubmVvNGouaW8iLCJodHRwczovL25lbzRqLWRldi5ldS5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNzA1NTgyNzYzLCJleHAiOjE3MDU2NjkxNjMsImF6cCI6ImNWdlpMeW93ZzdXV09acUlxR0VQM05DU2VtbWRMa2RMIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCIsIm9yZ19pZCI6Im9yZ19uYVR1SWRzbmpIWjJ4RFlRIn0.REXbTiC3Br4oJJDUspAwGmffn3-Pyk8NcFQ0sywKIVXra5UBUGFMweLFvnGcZ7UU5DTwt0TdUBOFnpXM4eHWz7TrokMe1H0_wxjiUkP-fMyNNKMexEodo8_afC61xkIYleuqV25S79fZthSnUWV7B2_cxXfI-2QW3ETfH0nKvpPxp21gF1anAUHFYhG1eFy0T2hCuL04JmyaLpbBAbMx_TwGDAeefpcj3X0SmfeQiF60lrG0p7z62lDmwNWbjbAwKNBJEmBI9hfCktjDFGBLGr5wwLh3cNXm-HV_EDiEl4u_t9VbFnaG62oma23m6lef4V0OrIbSGdQTUm4KomM_Bg';

export const FAKE_ORG_JWT_ID = 'org_naTuIdsnjHZ2xDYQ';

let currentJWT = FAKE_JWT;

export const setCurrentJwt = (jwt: string) => {
  currentJWT = jwt;
};
export default class FakeAuthentication {
  onAuthenticating;
  onAuthenticated;
  private _emulatedLatency: number;

  constructor(emulatedLatency = 0) {
    this._emulatedLatency = emulatedLatency;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logout(returnTo) {
    Actions.navigate.push(returnTo);
  }

  async getTokenForConnectionAndEmail() {
    return Promise.resolve('fake-token');
  }

  authenticate = () => {
    this._emulateLatency(() => {
      this.onAuthenticated(null, {
        accessToken: currentJWT,
        profile: { name: 'John Doe', email: 'jd@example.com' },
        ...partnerPunchoutHelper(),
      });
    });
  };

  _emulateLatency = cb => {
    if (!this._emulatedLatency) {
      // Makes testing easier by not breaking execution up via setTimeout(.., 0)
      cb();
      return;
    }
    setTimeout(cb, this._emulatedLatency);
  };
}
