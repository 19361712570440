import CustomerResource from 'remote/resources/customer';
import logger from 'logger';
import messages from 'remote/error-handler/error-messages';
import { ErrorReasons } from 'remote/error-handler/error-reason-codes';
import Actions from 'actions';
import { TokenResult } from '@stripe/stripe-js';
import {
  Customer,
  CustomerCardDetails,
  CustomerResponse,
  transformAddressToBillingAddress,
  transformBillingAddressToAddress,
} from 'types/customer';

const goToAccountPage = () => {
  Actions.navigate.push({ hash: '#account' });
};

const createCustomer = (tenantId: string, data: TokenResult) =>
  CustomerResource.createCustomer(tenantId, data)
    .then(() => {
      Actions.namespaces.refresh();

      // Refetch user details as permissions might change depending on if the
      // user has a cc or not
      Actions.userDetails.refetch();
    })
    .catch(error => {
      logger.debug('Unable to store customer information:', error);
      return handleSaveCardError(error);
    });

const updateCustomerEmail = (tenantId: string, email: string): Promise<Customer> =>
  CustomerResource.updateCustomer(tenantId, { email })
    .then(res => {
      Actions.namespaces.refresh();
      Actions.userDetails.refetch();
      return {
        email: res.email,
      };
    })
    .catch(error => {
      logger.debug('Unable to update customer information:', error);
      throw error;
    });

const updateCustomer = (tenantId: string, data: any): Promise<Customer> =>
  CustomerResource.updateCustomer(tenantId, {
    data: {
      ...data,
      address: data.address && transformBillingAddressToAddress(data.address),
      ...(data.shipping
        ? {
            shipping: {
              ...data.shipping,
              address: transformBillingAddressToAddress(data.shipping.address),
            },
          }
        : {}),
    },
  })
    .then(res => {
      Actions.namespaces.refresh();
      Actions.userDetails.refetch();
      return transformResponse(res);
    })
    .catch(error => {
      logger.debug('Unable to update customer information:', error);
      throw error;
    });

const addCustomerCard = (tenantId: string, data: TokenResult): Promise<CustomerCardDetails> =>
  CustomerResource.addCard(tenantId, data)
    .then(card => {
      Actions.namespaces.refresh();
      Actions.userDetails.refetch();
      return {
        last4: card.Last4,
        brand: card.Brand,
        expMonth: card.ExpMonth,
        expYear: card.ExpYear,
        name: card.Name,
        address: card.Address ? transformAddressToBillingAddress(card.Address) : undefined,
      };
    })
    .catch(error => {
      const errorLogMessage =
        error.response && error.response.status
          ? ` ${error.response.status} returned from server.`
          : `${error}`;
      logger.debug('Caught error while adding customer card information: ', errorLogMessage);
      throw error;
    });

const handleSaveCardError = (errorDetails: any = {}) => {
  const { error = {} }: any = errorDetails;
  switch (error.Reason) {
    case ErrorReasons.CARD_FUNDING_REJECTION:
      return {
        error: `${messages.cardNotUpdated}. ${errorDetails.error.Message}`,
      };

    default:
      return {
        error: `${messages.cardNotUpdated}. Please check the details and try again.`,
      };
  }
};

const getCustomer = (tenantId: string): Promise<Customer> =>
  CustomerResource.getCustomer(tenantId)
    .then(res => {
      return transformResponse(res);
    })
    .catch(error => {
      logger.debug('Caught error while fetching billing status:', error);
      throw error;
    });

const fetchCard = (tenantId: string): Promise<CustomerCardDetails> =>
  CustomerResource.getCard(tenantId)
    .then(card => {
      return {
        last4: card.Last4,
        brand: card.Brand,
        expMonth: card.ExpMonth,
        expYear: card.ExpYear,
        name: card.Name,
        address: card.Address ? transformAddressToBillingAddress(card.Address) : undefined,
      };
    })
    .catch(error => {
      logger.debug('Caught error while fetching customer card details:', error);
      throw error;
    });

const transformResponse = (res: CustomerResponse) => ({
  address: res.address ? transformAddressToBillingAddress(res.address) : undefined,
  serviceAddress: res.service_address
    ? transformAddressToBillingAddress(res.service_address)
    : undefined,
  companyName: res.company_name,
  billingName: res.billing_name,
  email: res.email,
});

export default {
  createCustomer,
  updateCustomer,
  updateCustomerEmail,
  getCustomer,
  addCustomerCard,
  goToAccountPage,
  fetchCard,
};
