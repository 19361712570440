import './metrics.css';
import React, { useState } from 'react';
import track, { useTracking } from 'react-tracking';

import DatabaseMetricCharts, { TimeWindow, TimeWindowValue } from './charts';
import DatabaseMetricTimeSelector from './time-selector';
import useMetrics from './use-metrics';
import { Button } from 'components/foundation';
import { useSession } from 'store';

const defaultTimeWindowOptions: TimeWindow[] = [
  { value: '6h', label: '6h' },
  { value: '24h', label: '24h' },
  { value: '3d', label: '3d' },
  { value: '7d', label: '7d' },
  { value: '30d', label: '30d' },
];

interface DatabaseMetricProps {
  dbId: string;
  opsManagerUrl?: string;
  timeWindowOptions?: TimeWindow[];
}

const DatabaseMetricChartsSection = ({
  dbId,
  opsManagerUrl,
  timeWindowOptions = defaultTimeWindowOptions,
}: DatabaseMetricProps) => {
  const session = useSession();
  const tracking = useTracking();
  const [timeWindow, setTimeWindow] = useState<TimeWindowValue>(timeWindowOptions[0].value);
  const { metrics, loading } = useMetrics(dbId, timeWindow);

  return (
    <div>
      <div className="tw-grid top-grid">
        <div className="n-subheading-large">Instance usage</div>
        <div className="tw-flex tw-flex-row tw-gap-4 tw-justify-end">
          {session.featureToggles.enable_ops_manager && opsManagerUrl && (
            <Button
              fill="outlined"
              color="neutral"
              iconName="ArrowTopRightOnSquareIconOutline"
              href={opsManagerUrl}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                tracking.trackEvent({
                  view: 'metrics',
                  action: 'open_advanced_metrics',
                });
              }}
            >
              Advanced metrics
            </Button>
          )}
          <DatabaseMetricTimeSelector
            options={timeWindowOptions}
            onChange={value => {
              tracking.trackEvent({
                view: 'metrics',
                action: 'select_time_window',
                properties: {
                  'time-window': value,
                },
              });
              setTimeWindow(value);
            }}
            value={timeWindow}
          />
        </div>
      </div>
      <DatabaseMetricCharts timeWindow={timeWindow} metrics={metrics} loading={loading} />
    </div>
  );
};

export default track()(DatabaseMetricChartsSection);
