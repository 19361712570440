import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { DataGrid, Link, useDefaultTable } from 'components/foundation';
import { Organization } from 'types/organization';

interface Props {
  organizations: Organization[];
  className?: string;
}

const headerHelper = createColumnHelper<Organization>();

export const OrganizationsTable = ({ organizations }: Props) => {
  const columns = useMemo(
    () => [
      headerHelper.accessor('name', {
        header: 'Name',
        minSize: 100,
        cell: c => {
          const organization = c.row.original;
          return <Link href={`#admin/organizations/${organization.id}`}>{organization.name}</Link>;
        },
      }),
      headerHelper.accessor('displayName', {
        header: 'Display Name',
        minSize: 100,
      }),
      headerHelper.accessor('id', {
        header: 'Organization UUID',
        minSize: 100,
      }),
      headerHelper.accessor('auth0OrgId', {
        header: 'Auth0 Org Id',
        minSize: 100,
      }),
    ],
    []
  );

  const table = useDefaultTable({
    columns: columns,
    data: organizations,
  });

  return (
    <div className="tw-mt-6 tw-w-full">
      <div className="tw-mt-12">
        <DataGrid tableInstance={table} />
      </div>
    </div>
  );
};
