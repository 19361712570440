import React from 'react';

import { Alert, TextLink } from 'foundation';

const NEO4J_DOC_CDC_PREFIX = 'https://neo4j.com/docs/cdc/current';
const NEO4J_DOC_CDC_AURA = `${NEO4J_DOC_CDC_PREFIX}/get-started/aura`;
const CDCEnableLink = `${NEO4J_DOC_CDC_AURA}/#toggle-cdc-mode`;
const CDCDisableLink = `${NEO4J_DOC_CDC_AURA}/#disable-cdc`;
const PauseResumeLink = `${NEO4J_DOC_CDC_AURA}/#pause-resume`;
const RestoreLink = `${NEO4J_DOC_CDC_PREFIX}/backup-restore`;

export const CDCCloneDBWarning = () => (
  <Alert
    type="warning"
    description={
      <div className="tw-pb-3">
        The instance you are cloning from has Change Data Capture enabled. The enrichment mode of
        the cloned instance will be defaulted to OFF. Please re-enable your desired enrichment mode
        as soon as your instance is cloned. See{' '}
        <TextLink href={CDCEnableLink} externalLink>
          Enable CDC
        </TextLink>{' '}
        for more information.
      </div>
    }
    className="tw-mt-6"
  />
);

export const CDCPauseDBWarning = () => (
  <Alert
    type="warning"
    description={
      <div className="tw-pb-3">
        Pause/Resume is not recommended on a CDC enabled database, please stop your CDC application
        and reset your application cursor. Neo4j recommends disabling programmatic pausing of this
        database. Please read the{' '}
        <TextLink href={PauseResumeLink} externalLink>
          Paused/Resumed databases
        </TextLink>{' '}
        for more info.
      </div>
    }
    className="tw-mt-6"
  />
);

export const CDCEnableWarning = () => (
  <>
    <Alert
      type="warning"
      description={
        <div className="tw-pb-3">
          You are enabling Change Data Capture on the instance. Please read{' '}
          <TextLink href={NEO4J_DOC_CDC_AURA} externalLink>
            CDC on Neo4j Aura
          </TextLink>
          .
        </div>
      }
      className="tw-my-6"
    />
    <Alert
      type="warning"
      description="Before you turn on CDC, please ensure you have disabled any programmatic pausing of the database. If you need to pause your instance, please disable CDC first."
      className="tw-mt-6"
    />
  </>
);

export const CDCDisableWarning = () => (
  <Alert
    type="warning"
    description={
      <div>
        Please note, applications dependent on CDC will stop working. See{' '}
        <TextLink href={CDCDisableLink} externalLink>
          Disable CDC
        </TextLink>{' '}
        for more info.
      </div>
    }
    className="tw-mt-6"
  />
);

export const CDCRestoreSnapshotWarning = () => (
  <Alert
    type="warning"
    description={
      <div>
        <div className="tw-pb-3">
          CDC is defaulted to OFF when restored from snapshot. Please re-enable your desired{' '}
          enrichment mode as soon as your instance has been restored. See{' '}
          <TextLink href={CDCEnableLink} externalLink>
            Enable CDC
          </TextLink>
          for more information.
        </div>
        <div>
          Please stop your CDC application and reset your application cursor. Please refer to{' '}
          <TextLink href={RestoreLink} externalLink>
            Guidance on Back and Restore
          </TextLink>{' '}
          for more info.
        </div>
      </div>
    }
    className="tw-my-6"
  />
);

export const CDCRestoreSnapshotTableWarning = () => (
  <Alert
    type="warning"
    description={
      <p>
        If Change Data Capture (CDC) is enabled on your instance, restoring from snapshot will
        default your instance to CDC disabled. Please re-enable your desired enrichment mode as soon
        as your instance is restored. See{' '}
        <TextLink href={CDCEnableLink} externalLink>
          Toggle CDC mode
        </TextLink>{' '}
        for more information.
      </p>
    }
    className="tw-my-6"
  />
);
