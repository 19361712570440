import UserDetailsResource from 'remote/resources/user-details';
import { SessionStore } from 'state';
import logger from 'logger';

const updateAcceptedTermsAndConditions = newValue =>
  UserDetailsResource.update(newValue)
    .then(() => {
      SessionStore.setAcceptedTsAndCs(newValue.AcceptedTermsAndConditions);
      if ('MarketingPreference' in newValue) {
        SessionStore.setMarketingChoiceRequired(null);
      }
    })
    .catch(error => {
      logger.debug('Caught error while setting AcceptedTermsAndConditions:', error);
    });

const updateWorkspaceEnabled = (newValue: boolean) =>
  UserDetailsResource.update({ WorkspaceEnabled: newValue })
    .then(() => {
      SessionStore.setWorkspaceEnabled(newValue);
    })
    .catch(error => {
      logger.debug('Caught error while setting WorkspaceEnabled:', error);
    });

const refetch = async () => {
  const userDetails = await UserDetailsResource.get();
  SessionStore.setUserDetails({
    userId: userDetails.UserId,
    acceptedTsAndCs: userDetails.AcceptedTermsAndConditions,
    workspaceEnabled: userDetails.WorkspaceEnabled,
    permissions: userDetails.Permissions,
    marketingChoiceRequired: userDetails.MarketingChoiceRequired,
  });
};

export default { updateAcceptedTermsAndConditions, updateWorkspaceEnabled, refetch };
