import React from 'react';
import EnterpriseForm, {
  validate as validateEnterprise,
  defaults as enterpriseDefaults,
} from 'application/create-db/form-enterprise';
import CloneDataScienceForm, {
  validate as validateCloneDataScience,
  defaults as dataScienceDefaults,
} from './form-clone-data-science';
import CloneDSEForm, {
  validate as validateCloneDSE,
  defaults as dseDefaults,
} from './form-clone-dse';
import CreateDseForm, {
  validate as validateCreateDse,
} from 'components/application/create-db/form-dse';
import GdsForm, { validate as validateCreateGds } from 'application/create-db/form-gds';
import { Validation } from 'utils/validation';
import { Tier, getDefaultName } from 'entities/database';
import { CloneToNewDatabaseFormData, CloneToNewDatabaseFormOptions } from './form-data';
import {
  validate as validateFree,
  defaults as freeDefaults,
} from 'application/create-db/free-tier';
import ProfessionalForm, {
  validate as validateProfessional,
  defaults as professionalDefaults,
} from 'application/create-db/form-professional';

export const validate = (
  data: CloneToNewDatabaseFormData,
  options: CloneToNewDatabaseFormOptions,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => {
  switch (options.targetTier) {
    case Tier.PROFESSIONAL:
      return validateProfessional(data, { onlyRequired });
    case Tier.FREE:
      return validateFree(data, { onlyRequired });
    case Tier.ENTERPRISE:
    case Tier.MTE:
      return validateEnterprise(data, { onlyRequired });
    case Tier.GDS:
      return Tier.GDS === options.database.Tier
        ? validateCloneDataScience(data, { onlyRequired })
        : validateCreateGds(data, { onlyRequired });
    case Tier.AURA_DSE:
      return Tier.AURA_DSE === options.database.Tier
        ? validateCloneDSE(data, { onlyRequired })
        : validateCreateDse(data, { onlyRequired });
    default:
      throw new Error(`Invalid tier: ${options.targetTier}`);
  }
};

export const defaults = (options: CloneToNewDatabaseFormOptions): CloneToNewDatabaseFormData => {
  const defaultName = getDefaultName(options.database.Name, 'Clone');
  switch (options.targetTier) {
    case Tier.PROFESSIONAL:
      return { ...professionalDefaults(), name: defaultName };
    case Tier.FREE:
      return { ...freeDefaults(options.providerConfigs), name: defaultName };
    case Tier.ENTERPRISE:
    case Tier.MTE:
      return { ...enterpriseDefaults(options), name: defaultName };
    case Tier.GDS:
      return dataScienceDefaults(options);
    case Tier.AURA_DSE:
      return dseDefaults(options);
    default:
      throw new Error(`Invalid tier: ${options.targetTier}`);
  }
};

interface Props {
  data: CloneToNewDatabaseFormData;
  options: CloneToNewDatabaseFormOptions;
  onChange: (data: CloneToNewDatabaseFormData) => any;
  validation: Validation<CloneToNewDatabaseFormData>;
  disabled?: boolean;
}

const CloneToNewDatabaseForm = ({ options, data, onChange, validation, disabled }: Props) => {
  switch (options.targetTier) {
    case Tier.FREE:
    case Tier.PROFESSIONAL:
    case Tier.MTE:
      return (
        <ProfessionalForm
          data={data}
          options={options}
          onChange={onChange}
          validation={validation}
          disabled={disabled}
        />
      );
    case Tier.ENTERPRISE:
      return (
        <EnterpriseForm
          data={data}
          options={options}
          onChange={onChange}
          validation={validation}
          disabled={disabled}
        />
      );
    case Tier.GDS:
      return Tier.GDS === options.database.Tier ? (
        <CloneDataScienceForm
          data={data}
          options={options}
          onChange={onChange}
          validation={validation}
          disabled={disabled}
        />
      ) : (
        <GdsForm
          data={data}
          options={options}
          onChange={onChange}
          validation={validation}
          disabled={disabled}
          nodeCount={options.database.Counts?.CurrentNodes}
          relationshipCount={options.database.Counts?.CurrentRelationships}
          cloning={true}
        />
      );
    case Tier.AURA_DSE:
      return Tier.AURA_DSE === options.database.Tier ? (
        <CloneDSEForm
          data={data}
          options={options}
          onChange={onChange}
          validation={validation}
          disabled={disabled}
        />
      ) : (
        <CreateDseForm
          data={data}
          options={options}
          onChange={onChange}
          validation={validation}
          disabled={disabled}
          nodeCount={options.database.Counts?.CurrentNodes}
          relationshipCount={options.database.Counts?.CurrentRelationships}
          cloning={true}
        />
      );
    default:
      throw new Error(`Invalid tier: ${options.targetTier}`);
  }
};

export default CloneToNewDatabaseForm;
